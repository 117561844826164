import React, { useState, useContext, useEffect } from 'react';
import CheckoutContext from '../../context/checkout/checkoutContext';

const CheckoutForm = () => {
  const checkoutContext = useContext(CheckoutContext);

  const { updateCheckout, clearCurrentCheckout, currentCheckout } = checkoutContext;

  useEffect(() => {
    if (currentCheckout !== null) {
      setCheckout(currentCheckout);
    }
  }, [checkoutContext, currentCheckout]);

  const [checkout, setCheckout] = useState({});
  const [amount, setAmount] = useState(0);
  const [note, setNote] = useState("");

  const { _id, name, phone, visits } = checkout;

  const onSubmit = e => {
    e.preventDefault();
    if (currentCheckout !== null) {
      updateCheckout({ _id, note, amount});
    }
    clearAll();
  };

  const onChangeAmount = e => setAmount(e.target.value);
  const onChangeNote = e => setNote(e.target.value);

  const clearAll = () => {
    clearCurrentCheckout();
  };

  return (
    <div id="checkout-modal" className="modal">
      <form onSubmit={onSubmit}>
      <div className="row">
        <div className="modal-content">
          <h6 className="center-align">
            CHECKOUT
          </h6>
          <div className="input-field">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={name}
              readOnly
            />
          </div>
          <div className="input-field">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="Phone (123-456-7890)"
              name="phone"
              value={phone}
              readOnly
            />
          </div>
          <div className="input-field">
            <label htmlFor="visits">visits</label>
            <input
              type="number"
              placeholder="0"
              name="visits"
              value={visits}
              readOnly
            />
          </div>
          <div className="input-field">
            <label htmlFor="amount">Total Amount $ Paid</label>
            <input
              type="number"
              name="amount"
              value={amount}
              onChange={onChangeAmount}
            />
          </div>
          <div className="input-field">
            <label htmlFor="note">Note</label>
            <textarea className="materialize-textarea"
              name="note"
              value={note}
              onChange={onChangeNote}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="modal-footer">
          <button
            type="submit"
            className="modal-close btn-large btn-flat">
           <i className="material-icons right">send</i>Checkout
           </button>
        </div>
      </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
