import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../context/auth/authContext';
import CheckinForm from '../checkins/CheckinForm';
import TermsAndPolicies from '../checkins/TermsAndPolicies';
import PageContext from '../../context/page/pageContext';
import { isMobileOnly } from 'react-device-detect';

const CheckinsPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Check In")
    // eslint-disable-next-line
  }, []);

  if (isMobileOnly) {
    return (
      <div className="row">
        <div className="col s12">
          <div className="card-panel white center-align">
            <i className="material-icons black-text left">error</i>
            <span className="black-text center">
              Using mobile phone to check in customers is not supported
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <TermsAndPolicies />
      <CheckinForm />
    </div>
  );
};

export default CheckinsPage;
