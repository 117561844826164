import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CheckoutContext from '../../context/checkout/checkoutContext';
import Avatar from 'react-avatar';
import moment from "moment";

const CheckoutItem = ({ checkout }) => {
  const checkoutContext = useContext(CheckoutContext);
  const { setCurrentCheckout } = checkoutContext;

  const { name, phone, points, visits, inServiceTime } = checkout;

  return (
    <tr role="row">
      <td><a href="#checkout-modal" className="modal-trigger" onClick={() => setCurrentCheckout(checkout)}><Avatar name={name} size="50px" color="#b2dfdb" round /></a></td>
      <td><a href="#checkout-modal" className="modal-trigger black-text" onClick={() => setCurrentCheckout(checkout)}>{name}</a></td>
      <td><a href="#checkout-modal" className="modal-trigger black-text" onClick={() => setCurrentCheckout(checkout)}>{phone}</a></td>
      <td><a href="#checkout-modal" className="modal-trigger black-text" onClick={() => setCurrentCheckout(checkout)}>{moment(inServiceTime).format('MMMM Do YYYY, h:mm:ss a')}</a></td>
      <td><a href="#checkout-modal" className="modal-trigger black-text" onClick={() => setCurrentCheckout(checkout)}>{points}</a></td>
      <td><a href="#checkout-modal" className="modal-trigger" onClick={() => setCurrentCheckout(checkout)}><span className={'white-text badge ' + (visits >= 3 ? (visits >= 9 ? 'pink lighten-2' : 'teal lighten-2') : 'blue lighten-2')}>{(visits >= 3 ? (visits >= 9 ? 'VIP' : 'Regular') : 'New')}</span></a></td>
    </tr>
  );
};

CheckoutItem.propTypes = {
  checkout: PropTypes.object.isRequired
};

export default CheckoutItem;
