import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RewardContext from '../../context/reward/rewardContext';

const RewardItem = ({ reward }) => {
  const rewardContext = useContext(RewardContext);
  const { deleteReward, setCurrentReward, clearCurrentReward } = rewardContext;

  const { _id, visits, points, discount, type } = reward;

  const onDelete = () => {
    deleteReward(_id);
    clearCurrentReward();
  };

  return (
    <tr role="row">
      {visits!==0?
      (<td>{visits} visits</td>)
      :
      (<td></td>)
      }
      {points!==0?
      (<td>{points} points</td>)
      :
      (<td></td>)
      }
      <td>
        {type === "$" ? "$"+ (discount) : (discount) + "%"}
      </td>
      <td>
        <a href="#add-reward-modal" className="waves-effect waves-light modal-trigger" onClick={() => setCurrentReward(reward)}>
          <i className="material-icons teal-text">edit</i>
        </a>
      </td>
      <td>
        <a href="#" className="waves-effect waves-light modal-trigger" onClick={onDelete}>
          <i className="material-icons red-text">delete</i>
        </a>
      </td>
    </tr>
  );
};

RewardItem.propTypes = {
  reward: PropTypes.object.isRequired
};

export default RewardItem;
