import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/layout/Navbar';

import Alerts from './components/layout/Alerts';
import PrivateRoute from './components/routing/PrivateRoute';

import CustomerState from './context/customer/CustomerState';
import PromotionState from './context/promotion/PromotionState';
import ReminderState from './context/reminder/ReminderState';
import RewardState from './context/reward/RewardState';
import SettingState from './context/setting/SettingState';
import StaffState from './context/staff/StaffState';
import ServiceState from './context/service/ServiceState';
import CheckinState from './context/checkin/CheckinState';
import CheckoutState from './context/checkout/CheckoutState';
import ReviewState from './context/review/ReviewState';
import AppointmentState from './context/appointment/AppointmentState';
import CalendarState from './context/calendar/CalendarState';
import AuthState from './context/auth/AuthState';
import PageState from './context/page/PageState';
import AlertState from './context/alert/AlertState';
import setAuthToken from './utils/setAuthToken';

import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './admin-materialize.min.css';

import RegisterPage from './components/pages/RegisterPage';
import LoginPage from './components/pages/LoginPage';
import DashboardPage from './components/pages/DashboardPage';
import SettingsPage from './components/pages/SettingsPage';
import FeedbacksPage from './components/pages/FeedbacksPage';
import AppointmentsPage from './components/pages/AppointmentsPage';
import CalendarPage from './components/pages/CalendarPage';
import CustomersPage from './components/pages/CustomersPage';
import PromotionsPage from './components/pages/PromotionsPage';
import RemindersPage from './components/pages/RemindersPage';
import RewardsPage from './components/pages/RewardsPage';
import ReviewsPage from './components/pages/ReviewsPage';
import CheckinsPage from './components/pages/CheckinsPage';
import CheckoutsPage from './components/pages/CheckoutsPage';
import StaffsPage from './components/pages/StaffsPage';
import ServicesPage from './components/pages/ServicesPage';
import Footer from './components/layout/Footer';

import './App.css';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect( () => {
    // Init Materialize JS
    M.AutoInit();
    M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'),
    {
      alignment: 'right',
      constrainWidth: false,
      coverTrigger: false,
      closeOnClick: false,
    });
    //M.textareaAutoResize(document.querySelectorAll('.review-comment'));
    M.Datepicker.init(document.querySelectorAll('.datepicker'),
    {
      minDate: new Date(),
    });
    M.Modal.init(document.querySelectorAll('.modal'), {
      dismissible: true,
    });
  });

  return (
    <PageState>
      <AuthState>
        <CustomerState>
          <StaffState>
            <ServiceState>
              <CheckinState>
                <SettingState>
                  <CheckoutState>
                    <ReviewState>
                    <PromotionState>
                    <ReminderState>
                    <RewardState>
                      <AppointmentState>
                        <CalendarState>
                          <AlertState>
                            <Router>
                              <Fragment>
                                <Navbar />
                                <main>
                                <div className='container'>
                                  <Alerts />
                                  <Switch>
                                    <Route exact path='/register' component={RegisterPage} />
                                    <Route exact path='/login' component={LoginPage} />
                                    <Route exact path='/feedbacks/*' component={FeedbacksPage} />
                                    <Route exact path='/appointments/*' component={AppointmentsPage} />
                                    <PrivateRoute exact path='/' component={DashboardPage} />
                                    <PrivateRoute exact path='/calendar' component={CalendarPage} />
                                    <PrivateRoute exact path='/checkins' component={CheckinsPage} />
                                    <PrivateRoute exact path='/checkouts' component={CheckoutsPage} />
                                    <PrivateRoute exact path='/customers' component={CustomersPage} />
                                    <PrivateRoute exact path='/promotions' component={PromotionsPage} />
                                    <PrivateRoute exact path='/reminders' component={RemindersPage} />
                                    <PrivateRoute exact path='/rewards' component={RewardsPage} />
                                    <PrivateRoute exact path='/staffs' component={StaffsPage} />
                                    <PrivateRoute exact path='/services' component={ServicesPage} />
                                    <PrivateRoute exact path='/reviews' component={ReviewsPage} />
                                    <PrivateRoute exact path='/settings' component={SettingsPage} />
                                  </Switch>
                                </div>
                                </main>
                                <Footer />
                              </Fragment>
                            </Router>
                          </AlertState>
                        </CalendarState>
                      </AppointmentState>
                    </RewardState>
                    </ReminderState>
                    </PromotionState>
                    </ReviewState>
                  </CheckoutState>
                </SettingState>
              </CheckinState>
            </ServiceState>
          </StaffState>
        </CustomerState>
      </AuthState>
    </PageState>
  );
};

export default App;
