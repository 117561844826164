import React, { useState, useContext, useEffect } from 'react';
import PromotionContext from '../../context/promotion/promotionContext';
import AlertContext from '../../context/alert/alertContext';

const PromotionForm = () => {
  const promotionContext = useContext(PromotionContext);
  const { promotions, addPromotion, updatePromotion, clearCurrentPromotion, currentPromotion } = promotionContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  useEffect(() => {
    if (currentPromotion !== null) {
      setPromotion(currentPromotion);
    } else {
      setPromotion({
        event: '',
        discount: 0,
        type: '$', 
        visits: 0,
        from: null,
        to: null
      });
    }
  }, [promotionContext, currentPromotion]);

  const [promotion, setPromotion] = useState({
    event: '',
    discount: 0,
    type: '$',
    visits: 0,
    from: null,
    to: null
  });

  const { discount, type, visits, from, to } = promotion;

  const onChange = e =>
    setPromotion({ ...promotion, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (from === null || to === null || discount === 0) {
      return;
    }
    if (currentPromotion === null) {
      if (promotions !== undefined && promotions.length !== 0) {
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0, 0);
        let latest = new Date(promotions[0].date);
        latest.setHours(0);
        latest.setMinutes(0);
        latest.setSeconds(0, 0);
        latest.setDate(latest.getDate() + 15);
        if (latest.getTime() > today.getTime()) {
          setAlert(`You are only allowed to add 1 promotion per 15 days!`, 'red', 10000);
          return;
        }
      }
      addPromotion(promotion);
    } else {
      updatePromotion(promotion);
    }
    clearAll();
  };

  const clearAll = () => {
    clearCurrentPromotion();
  };

  return (
    <div id="add-promotion-modal" className="modal">
      <form onSubmit={onSubmit}>
      <div className="modal-content">
        <h6 className="center-align">
          {currentPromotion ? 'Edit Promotion' : 'Add Promotion'}
        </h6>
        <div class="row">
          <div className="input-field col s12">
            <label htmlFor="visits">For Customers Having # Visits</label>
            <input
              type="number"
              placeholder=""
              name="visits"
              value={visits}
              onChange={onChange}
            />
          </div>
        </div>
        <div class="row">
          <div className="input-field col s6">
            <label htmlFor="from">Start On</label>
            <input name="from" 
              type="date" 
              placeholder=""
              value={from}
              onChange={onChange}
            />
          </div>
          <div className="input-field col s6">
            <label htmlFor="to">End On</label>
            <input name="to" 
              type="date" 
              placeholder=""
              value={to}
              onChange={onChange}
            />
          </div>
        </div>
        <div class="row">
          <div className="input-field col s4">
            <label htmlFor="discount">Discount</label>
            <input
              type="number"
              placeholder=""
              name="discount"
              value={discount}
              onChange={onChange}
            />
          </div>
          <div className="input-field col s8">
            <p className="col s4">
              <label>
                <input name="type" type="radio" value="$" checked={type==="$"?true:false} onChange={onChange}/>
                <span>$</span>
              </label>
            </p>
            <p className="col s4">
              <label>
                <input name="type" type="radio" value="%" checked={type==="%"?true:false} onChange={onChange}/>
                <span>%</span>
              </label>
            </p>    
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="modal-close btn-large btn-flat"><i className="material-icons right">{currentPromotion ? "edit" : "add"}</i>{currentPromotion ? "Edit Promotion" : "Add Promotion"}
        </button>
      </div>
      </form>
    </div>
  );
};

export default PromotionForm;
