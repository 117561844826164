import React, { useContext, useEffect, useState, useRef } from 'react';
import AuthContext from '../../context/auth/authContext';
import AddRewardBtn from '../rewards/AddRewardBtn';
import Rewards from '../rewards/Rewards';
import RewardForm from '../rewards/RewardForm';
import PageContext from '../../context/page/pageContext';

const RewardsPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Rewards")
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row">
      <AddRewardBtn />
      <RewardForm />
      <div className="card">
        <div className="row">
          <Rewards />
        </div>
      </div>
    </div>
  );
};

export default RewardsPage;
