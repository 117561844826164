import {
  UPDATE_CHECKIN,
  REDEEM_CHECKIN,
  UPLOAD_SIGNATURE,
  ADD_CHECKIN,
  SET_CURRENT_CHECKIN,
  CLEAR_CURRENT_CHECKIN,
  CLEAR_CHECKIN_MSG,
  CHECKIN_ERROR
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_CHECKIN:
      return {
        ...state,
        checkinMsg: action.payload
      };
    case REDEEM_CHECKIN:
      return {
        ...state
      };
    case UPLOAD_SIGNATURE:
      return {
        ...state
      };
    case ADD_CHECKIN:
      return {
        ...state,
        currentCheckin: action.payload
      };
    case SET_CURRENT_CHECKIN:
      return {
        ...state,
        currentCheckin: action.payload
      };
    case CLEAR_CURRENT_CHECKIN:
      return {
        ...state,
        currentCheckin: null
      };
    case CLEAR_CHECKIN_MSG:
      return {
        ...state,
        checkinMsg: null
      };
    case CHECKIN_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
