import React, { Fragment, useContext, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ServiceItem from './ServiceItem';
import Spinner from '../layout/Spinner';
import ServiceContext from '../../context/service/serviceContext';

const Services = () => {
  const serviceContext = useContext(ServiceContext);

  const { services, getServices, loading } = serviceContext;

  useEffect(() => {
    getServices();
    // eslint-disable-next-line
  }, []);

  if (services !== null && services.length === 0 && !loading) {
    return (
    <Fragment>
      <table className="border highlight centered responsive-table" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th>Service Name</th>
            <th>Duration</th>
            <th>Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </Fragment>
    );
  }

  return (
    <Fragment>
      {services !== null && !loading ? (
        <TransitionGroup>
          <table className="border highlight centered responsive-table" cellSpacing="0" width="100%">
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Duration</th>
              <th>Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {services.map(service => (
            <CSSTransition
              key={service._id}
              timeout={500}
            >
              <ServiceItem service={service} />
            </CSSTransition>
          ))}
          </tbody>
          </table>
        </TransitionGroup>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default Services;
