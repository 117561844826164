import React, { useReducer } from 'react';
import axios from 'axios';
import ReminderContext from './reminderContext';
import reminderReducer from './reminderReducer';
import {
  GET_REMINDERS,
  ADD_REMINDER,
  DELETE_REMINDER,
  SET_CURRENT_REMINDER,
  CLEAR_CURRENT_REMINDER,
  UPDATE_REMINDER,
  CLEAR_REMINDERS,
  REMINDER_ERROR
} from '../types';

const ReminderState = props => {
  const initialState = {
    reminders: null,
    currentReminder: null,
    error: null
  };

  const [state, dispatch] = useReducer(reminderReducer, initialState);

  // Get Reminders
  const getReminders = async () => {
    try {
      const res = await axios.get('/api/reminders');

      dispatch({
        type: GET_REMINDERS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: REMINDER_ERROR,
        payload: err.response.data
      });
    }
  };

  // Add Reminder
  const addReminder = async reminder => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.post('/api/reminders', reminder, config);

      dispatch({
        type: ADD_REMINDER,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: REMINDER_ERROR,
        payload: err.response.data
      });
    }
  };

  // Delete Reminder
  const deleteReminder = async id => {
    try {
      await axios.delete(`/api/reminders/${id}`);

      dispatch({
        type: DELETE_REMINDER,
        payload: id
      });
    } catch (err) {
      dispatch({
        type: REMINDER_ERROR,
        payload: err.response.data
      });
    }
  };

  // Update Reminder
  const updateReminder = async reminder => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.put(
        `/api/reminders/${reminder._id}`,
        reminder,
        config
      );

      dispatch({
        type: UPDATE_REMINDER,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: REMINDER_ERROR,
        payload: err.response.data
      });
    }
  };

  // Clear Reminders
  const clearReminders = () => {
    dispatch({ type: CLEAR_REMINDERS });
  };

  // Set Current Reminder
  const setCurrentReminder = reminder => {
    dispatch({ type: SET_CURRENT_REMINDER, payload: reminder });
  };

  // Clear Current Reminder
  const clearCurrentReminder = () => {
    dispatch({ type: CLEAR_CURRENT_REMINDER });
  };

  return (
    <ReminderContext.Provider
      value={{
        reminders: state.reminders,
        currentReminder: state.currentReminder,
        error: state.error,
        addReminder,
        deleteReminder,
        setCurrentReminder,
        clearCurrentReminder,
        updateReminder,
        getReminders,
        clearReminders
      }}
    >
      {props.children}
    </ReminderContext.Provider>
  );
};

export default ReminderState;
