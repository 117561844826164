import React, { Fragment, useContext, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PromotionItem from './PromotionItem';
import Spinner from '../layout/Spinner';
import PromotionContext from '../../context/promotion/promotionContext';
//import Avatar from 'react-avatar';

const Promotions = () => {
  const promotionContext = useContext(PromotionContext);

  const { promotions, getPromotions, loading } = promotionContext;

  useEffect(() => {
    getPromotions();
    // eslint-disable-next-line
  }, []);

  if (promotions !== null && promotions.length === 0 && !loading) {
    return (
    <Fragment>
      <table className="border highlight centered responsive-table table-status-sheet" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th>Discount</th>
            <th>From</th>
            <th>To</th>
            <th>Status</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody></tbody>
        <tbody></tbody>
      </table>
    </Fragment>
    );
  }

  return (
    <Fragment>
      {promotions !== null && !loading ? (
        <TransitionGroup>
          <table className="border highlight centered responsive-table table-status-sheet" cellSpacing="0" width="100%">
          <thead>
            <tr>
              <th>Discount</th>
              <th>From</th>
              <th>To</th>
              <th>Status</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {promotions.map(promotion => (
            <CSSTransition
              key={promotion._id}
              timeout={500}
            >
              <PromotionItem promotion={promotion} />
            </CSSTransition>
          ))}
          </tbody>
          </table>
        </TransitionGroup>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default Promotions;
