import {
  GET_REWARDS,
  ADD_REWARD,
  DELETE_REWARD,
  SET_CURRENT_REWARD,
  CLEAR_CURRENT_REWARD,
  UPDATE_REWARD,
  REWARD_ERROR,
  CLEAR_REWARDS
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_REWARDS:
      return {
        ...state,
        rewards: action.payload,
        loading: false
      };
    case ADD_REWARD:
      return {
        ...state,
        rewards: [action.payload, ...state.rewards],
        loading: false
      };
    case UPDATE_REWARD:
      return {
        ...state,
        rewards: state.rewards.map(reward =>
          reward._id === action.payload._id ? action.payload : reward
        ),
        loading: false
      };
    case DELETE_REWARD:
      return {
        ...state,
        rewards: state.rewards.filter(
          reward => reward._id !== action.payload
        ),
        loading: false
      };
    case CLEAR_REWARDS:
      return {
        ...state,
        rewards: null,
        error: null,
        currentReward: null
      };
    case SET_CURRENT_REWARD:
      return {
        ...state,
        currentReward: action.payload
      };
    case CLEAR_CURRENT_REWARD:
      return {
        ...state,
        currentReward: null
      };
    case REWARD_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
