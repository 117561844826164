import {
  UPDATE_SETTING,
  UPDATE_BILL_SETTING,
  SETTING_ERROR,
  CLEAR_SETTING_ERRORS
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_SETTING:
      return {
        ...state,
        setting: action.payload
      };
    case UPDATE_BILL_SETTING:
      return {
        ...state,
        bill_setting: action.payload
      };
    case SETTING_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case CLEAR_SETTING_ERRORS:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
