import React, { useReducer } from 'react';
import CalendarContext from './calendarContext';
import calendarReducer from './calendarReducer';

import {
  ADD_EVENT
} from '../types';

const CalendarState = props => {
  const initialState = {
    events: []
  };

  const [state, dispatch] = useReducer(calendarReducer, initialState);

  // add event
  const addEvent = (event) => {
    dispatch({
      type: ADD_EVENT,
      payload: event
    });
  };

  return (
    <CalendarContext.Provider
      value={{
        events: state.events,
        addEvent
      }}
    >
      {props.children}
    </CalendarContext.Provider>
  );
};

export default CalendarState;
