// Customer
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
export const CLEAR_CURRENT_CUSTOMER = 'CLEAR_CURRENT_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const FILTER_CUSTOMERS = 'FILTER_CUSTOMERS';
export const CLEAR_CUSTOMERS = 'CLEAR_CUSTOMERS';
export const CLEAR_FILTER_CUSTOMERS = 'CLEAR_FILTER_CUSTOMERS';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';
// Alert
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
// Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
// Page
export const SELECT_PAGE = 'SELECT_PAGE';

// Calendar
export const ADD_EVENT = 'ADD_EVENT';

// Checkin
export const UPDATE_CHECKIN = 'UPDATE_CHECKIN';
export const REDEEM_CHECKIN = 'REDEEM_CHECKIN';
export const UPLOAD_SIGNATURE = 'UPLOAD_SIGNATURE';
export const ADD_CHECKIN = 'ADD_CHECKIN';
export const SET_CURRENT_CHECKIN = 'SET_CURRENT_CHECKIN';
export const CLEAR_CURRENT_CHECKIN = 'CLEAR_CURRENT_CHECKIN';
export const CLEAR_CHECKIN_MSG = 'CLEAR_CHECKIN_MSG';
export const CHECKIN_ERROR = 'CHECKIN_ERROR';


// Checkout
export const GET_CHECKOUTS = 'GET_CHECKOUTS';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
export const SET_CURRENT_CHECKOUT = 'SET_CURRENT_CHECKOUT';
export const CLEAR_CURRENT_CHECKOUT = 'CLEAR_CURRENT_CHECKOUT';
export const UPDATE_CHECKOUT = 'UPDATE_CHECKOUT';

// Review
export const GET_REVIEWS = 'GET_REVIEWS';
export const REVIEW_ERROR = 'REVIEW_ERROR';
export const GET_CURRENT_REVIEW = 'GET_CURRENT_REVIEW';
export const SET_CURRENT_REVIEW = 'SET_CURRENT_REVIEW';
export const CLEAR_CURRENT_REVIEW = 'CLEAR_CURRENT_REVIEW';
export const UPDATE_REVIEW = 'UPDATE_REVIEW';

// Staff
export const GET_STAFFS = 'GET_STAFFS';
export const ADD_STAFF = 'ADD_STAFF';
export const DELETE_STAFF = 'DELETE_STAFF';
export const SET_CURRENT_STAFF = 'SET_CURRENT_STAFF';
export const SET_CURRENT_SHIFT = 'SET_CURRENT_SHIFT';
export const CLEAR_CURRENT_STAFF = 'CLEAR_CURRENT_STAFF';
export const UPDATE_STAFF = 'UPDATE_STAFF';
export const CLEAR_STAFFS = 'CLEAR_STAFFS';
export const STAFF_ERROR = 'STAFF_ERROR';

// Service
export const GET_SERVICES = 'GET_SERVICES';
export const ADD_SERVICE = 'ADD_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const SET_CURRENT_SERVICE = 'SET_CURRENT_SERVICE';
export const CLEAR_CURRENT_SERVICE = 'CLEAR_CURRENT_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const CLEAR_SERVICES = 'CLEAR_SERVICES';
export const SERVICE_ERROR = 'SERVICE_ERROR';

// Setting
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const UPDATE_BILL_SETTING = 'UPDATE_BILL_SETTING';
export const SETTING_ERROR = 'SETTING_ERROR';
export const CLEAR_SETTING_ERRORS = 'CLEAR_SETTING_ERRORS';

// Appointment
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_CURRENT_APPOINTMENT = 'GET_CURRENT_APPOINTMENT';
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const SET_CURRENT_APPOINTMENT = 'SET_CURRENT_APPOINTMENT';
export const CLEAR_CURRENT_APPOINTMENT = 'CLEAR_CURRENT_APPOINTMENT';
export const APPOINTMENT_ERROR = 'APPOINTMENT_ERROR';

// Promotion
export const GET_PROMOTIONS = 'GET_PROMOTIONS';
export const ADD_PROMOTION = 'ADD_PROMOTION';
export const DELETE_PROMOTION = 'DELETE_PROMOTION';
export const SET_CURRENT_PROMOTION = 'SET_CURRENT_PROMOTION';
export const CLEAR_CURRENT_PROMOTION = 'CLEAR_CURRENT_PROMOTION';
export const UPDATE_PROMOTION = 'UPDATE_PROMOTION';
export const CLEAR_PROMOTIONS = 'CLEAR_PROMOTIONS';
export const PROMOTION_ERROR = 'PROMOTION_ERROR';

// Reminder
export const GET_REMINDERS = 'GET_REMINDERS';
export const ADD_REMINDER = 'ADD_REMINDER';
export const DELETE_REMINDER = 'DELETE_REMINDER';
export const SET_CURRENT_REMINDER = 'SET_CURRENT_REMINDER';
export const CLEAR_CURRENT_REMINDER = 'CLEAR_CURRENT_REMINDER';
export const UPDATE_REMINDER = 'UPDATE_REMINDER';
export const CLEAR_REMINDERS = 'CLEAR_REMINDERS';
export const REMINDER_ERROR = 'REMINDER_ERROR';

// Reward
export const GET_REWARDS = 'GET_REWARDS';
export const ADD_REWARD = 'ADD_REWARD';
export const DELETE_REWARD = 'DELETE_REWARD';
export const SET_CURRENT_REWARD = 'SET_CURRENT_REWARD';
export const CLEAR_CURRENT_REWARD = 'CLEAR_CURRENT_REWARD';
export const UPDATE_REWARD = 'UPDATE_REWARD';
export const CLEAR_REWARDS = 'CLEAR_REWARDS';
export const REWARD_ERROR = 'REWARD_ERROR';
