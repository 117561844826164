import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import Reviews from '../reviews/Reviews';
import PageContext from '../../context/page/pageContext';
import ReviewForm from '../reviews/ReviewForm';

const ReviewsPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Reviews")
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row">
      <ReviewForm />
      <div className="card">
        <Reviews />
      </div>
    </div>
  );
};

export default ReviewsPage;
