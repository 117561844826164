import React, { useReducer } from 'react';
import axios from 'axios';
import CheckinContext from './checkinContext';
import checkinReducer from './checkinReducer';
import {
  CHECKIN_ERROR,
  UPDATE_CHECKIN,
  ADD_CHECKIN,
  REDEEM_CHECKIN,
  UPLOAD_SIGNATURE,
  SET_CURRENT_CHECKIN,
  CLEAR_CURRENT_CHECKIN,
  CLEAR_CHECKIN_MSG
} from '../types';

const CheckinState = props => {
  const initialState = {
    currentCheckin: null,
    checkinMsg: null,
    error: null
  };

  const [state, dispatch] = useReducer(checkinReducer, initialState);

  // Update Checkin
  const updateCheckin = async checkin => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { phone } = checkin
    try {
      const res = await axios.put(
        `/api/checkins`,
        { phone },
        config
      );

      dispatch({
        type: UPDATE_CHECKIN,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: CHECKIN_ERROR,
        payload: err.response.data
      });
    }
  };

  // Upload signature
  const uploadSignature = async (signature, filename) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    var blobBin = atob(signature.split(',')[1]);
    var array = [];
    for(var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], {type: 'image/png'});

    var formData = new FormData();
    formData.append("signature", file, filename);
    try {
      const res = await axios.post(
        '/api/signatures',
        formData,
        config
      );

      dispatch({
        type: UPLOAD_SIGNATURE,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: UPLOAD_SIGNATURE,
        payload: err.response.data
      });
    }
  }

  // Redeem
  const redeemCheckin = async checkin => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { phone, points, discount, type } = checkin
    try {
      const res = await axios.post(
        '/api/redeems',
        { phone, points, discount, type },
        config
      );

      dispatch({
        type: REDEEM_CHECKIN,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: CHECKIN_ERROR,
        payload: err.response.data
      });
    }
  };

  const addCheckin = async checkin => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.post(
        '/api/customers', 
        checkin, 
        config
      );

      dispatch({
        type: ADD_CHECKIN,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: CHECKIN_ERROR,
        payload: err.response.data
      });
    }
  };

  // Set Current Checkin
  const setCurrentCheckin = checkin => {
    dispatch({ type: SET_CURRENT_CHECKIN, payload: checkin });
  };

  // Clear Current Checkin
  const clearCurrentCheckin = () => {
    dispatch({ type: CLEAR_CURRENT_CHECKIN });
  };

  // Clear Checkin MSG
  const clearCheckinMsg = () => {
    dispatch({ type: CLEAR_CHECKIN_MSG });
  };

  return (
    <CheckinContext.Provider
      value={{
        currentCheckin: state.currentCheckin,
        checkinMsg: state.checkinMsg,
        error: state.error,
        updateCheckin,
        redeemCheckin,
        uploadSignature,
        addCheckin,
        setCurrentCheckin,
        clearCurrentCheckin,
        clearCheckinMsg,
      }}
    >
      {props.children}
    </CheckinContext.Provider>
  );
};

export default CheckinState;
