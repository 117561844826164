import React, { useReducer } from 'react';
import axios from 'axios';
import ReviewContext from './reviewContext';
import reviewReducer from './reviewReducer';
import {
  GET_REVIEWS,
  REVIEW_ERROR,
  GET_CURRENT_REVIEW,
  SET_CURRENT_REVIEW,
  CLEAR_CURRENT_REVIEW,
  UPDATE_REVIEW,
} from '../types';

const ReviewState = props => {
  const initialState = {
    reviews: null,
    currentReview: null,
    error: null
  };

  const [state, dispatch] = useReducer(reviewReducer, initialState);

  // Get reviews
  const getReviews = async () => {
    try {
      const res = await axios.get('/api/reviews');

      dispatch({
        type: GET_REVIEWS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: REVIEW_ERROR,
        payload: err.response.data
      });
    }
  };

  // Get current review
  const getCurrentReview = async id => {
    try {
      const review = await axios.get(`/api/reviews/${id}`);

      const user = await axios.get(`/api/users/${review.data.user}`);

      const customer = await axios.get(`/api/customers/${review.data.customer}`);

      dispatch({
        type: GET_CURRENT_REVIEW,
        payload: Object.assign(review.data, user.data, customer.data)
      });
    } catch (err) {
      dispatch({
        type: REVIEW_ERROR,
        payload: err
      });
    }
  };

  // Update review
  const updateReview = async review => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { _id, comment, rating } = review
    try {
      const res = await axios.put(
        `/api/reviews/${_id}`,
        { comment, rating },
        config
      );

      dispatch({
        type: UPDATE_REVIEW,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: REVIEW_ERROR,
        payload: err
      });
    }
  };

  // Set current review
  const setCurrentReview = async review => {
    try {
      const customer = await axios.get(`/api/customers/${review.customer}`);

      dispatch({ 
        type: SET_CURRENT_REVIEW,
        payload: Object.assign(review, customer.data)
      });
    } catch (err) {
      dispatch({
        type: REVIEW_ERROR,
        payload: err
      });
    }
  };

  // Clear current review
  const clearCurrentReview = () => {
    dispatch({ type: CLEAR_CURRENT_REVIEW });
  };

  return (
    <ReviewContext.Provider
      value={{
        reviews: state.reviews,
        currentReview: state.currentReview,
        error: state.error,
        getReviews,
        getCurrentReview,
        setCurrentReview,
        clearCurrentReview,
        updateReview,
      }}
    >
      {props.children}
    </ReviewContext.Provider>
  );
};

export default ReviewState;
