import React, { useContext } from 'react';
import RewardContext from '../../context/reward/rewardContext';

const AddRewardBtn = () => {
  const rewardContext = useContext(RewardContext);

  const { clearCurrentReward } = rewardContext;

  const clearAll = () => {
    clearCurrentReward();
  };

  return (
    <div className="fixed-action-btn">
      <a href="#add-reward-modal" className="btn-floating btn-large teal modal-trigger" onClick={clearAll}>
        <i className="large material-icons">add</i>
      </a>
    </div>
  )
}

export default AddRewardBtn;