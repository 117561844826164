import React, { useContext } from 'react';
import StaffContext from '../../context/staff/staffContext';

const AddStaffBtn = () => {
  const staffContext = useContext(StaffContext);

  const { clearCurrentStaff } = staffContext;

  const clearAll = () => {
    clearCurrentStaff();
  };

  return (
    <div className="fixed-action-btn">
      <a href="#staff-modal" className="btn-floating btn-large teal modal-trigger" onClick={clearAll}>
        <i className="large material-icons">add</i>
      </a>
    </div>
  )
}

export default AddStaffBtn;