import React, { Fragment, useContext, useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CustomerItem from './CustomerItem';
import Spinner from '../layout/Spinner';
import CustomerContext from '../../context/customer/customerContext';
import Avatar from 'react-avatar';

const Customers = () => {
  const customerContext = useContext(CustomerContext);

  const { customers, filteredCustomers, getCustomers, loading } = customerContext;

  const [pageNumber, setPageNumber] = useState(0);

  const NUMBER_OF_CUSTOMERS_PER_PAGE = 10;

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line
  }, []);

  const onNext = () => {
    if (pageNumber >= (customers.length/NUMBER_OF_CUSTOMERS_PER_PAGE - 1)) {
      setPageNumber(pageNumber);
    } else {
      setPageNumber(pageNumber+1);
    }
  };

  const onPrev = () => {
    if (pageNumber === 0) {
      setPageNumber(0);
    } else {
      setPageNumber(pageNumber-1);
    }
  };

  if (customers !== null && customers.length === 0 && !loading) {
    return (
    <Fragment>
      <table className="border highlight centered responsive-table table-status-sheet" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Phone</th>
            <th>Visits</th>
            <th>Last In Time</th>
            <th>Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </Fragment>
    );
  }

  return (
    <Fragment>
      {customers !== null && !loading ? (
        <TransitionGroup>
          <table className="border highlight centered responsive-table table-status-sheet" cellSpacing="0" width="100%">
          <thead>
            <tr>
              <th><Avatar name="" size="50px" color="#ffffffff" round /></th>
              <th>Name</th>
              <th>Phone</th>
              <th>Visits</th>
              <th>Last Check-In Time</th>
              <th>Type</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {filteredCustomers !== null
            ? filteredCustomers.map(customer => (
                <CSSTransition
                  key={customer._id}
                  timeout={500}
                >
                  <CustomerItem customer={customer} />
                </CSSTransition>
              ))
            : customers.slice(pageNumber*NUMBER_OF_CUSTOMERS_PER_PAGE, (pageNumber+1)*NUMBER_OF_CUSTOMERS_PER_PAGE).map(customer => (
                <CSSTransition
                  key={customer._id}
                  timeout={500}
                >
                  <CustomerItem customer={customer} />
                </CSSTransition>
              ))}
          </tbody>
          </table>
        </TransitionGroup>
      ) : (
        <Spinner />
      )}
      <ul className="pagination right">
        <li className="waves-effect"><a href="#" onClick={onPrev}><i className="material-icons">chevron_left</i></a></li>
        <li className="disabled"><a href="#">Prev</a></li>
        <li className="disabled"><a href="#">Next</a></li>
        <li className="waves-effect"><a href="#" onClick={onNext}><i className="material-icons">chevron_right</i></a></li>
      </ul>
    </Fragment>
  );
};

export default Customers;
