import React, { useState, useContext, useEffect } from 'react';
import ReviewContext from '../../context/review/reviewContext';

const ReviewForm = () => {
  const reviewContext = useContext(ReviewContext);

  const { currentReview } = reviewContext;

  useEffect(() => {
    if (currentReview !== null) {
      setReview(currentReview);
    } else {
      setReview({
        comment: '',
        rating: 0
      });
    }
  }, [reviewContext, currentReview]);

  const [review, setReview] = useState({
    comment: '',
    rating: 0
  });

  const { comment, customerName, customerPhone, customerVisits } = review;

  return (
    <div id="review-details-modal" className="modal">
      <div className="modal-content">
        <h6 className="center-align">
          Review Details
        </h6>
        <span>
          "{comment}"
        </span><br></br>
        <span className="right">
          {customerName}
        </span><br></br>
        <span className="right">
          Visited {customerVisits} times
        </span><br></br>
        <span className="right">
          {customerPhone}
        </span>
      </div>
    </div>
  );
};

export default ReviewForm;
