import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PromotionContext from '../../context/promotion/promotionContext';
import moment from "moment";

const PromotionItem = ({ promotion }) => {
  const promotionContext = useContext(PromotionContext);
  const { deletePromotion, setCurrentPromotion, clearCurrentPromotion, getPromotions } = promotionContext;

  const { _id, discount, type, from, to, sent } = promotion;

  const onDelete = () => {
    deletePromotion(_id);
    clearCurrentPromotion();
  };

  return (
    <tr role="row">
      <td>
        {type === "$" ? "$"+ (discount) : (discount) + "%"}
      </td>
      <td>{moment.utc(from).format('MMM DD, YYYY')}</td>
      <td>{moment.utc(to).format('MMM DD, YYYY')}</td>
      <td>{sent === true ? (<i className="material-icons black-text">done</i>) : (<i className="material-icons black-text">update</i>)}</td>
      <td>
        { sent === false ? 
        (<a href="#promotion-text-modal" className="waves-effect waves-light modal-trigger" onClick={() => setCurrentPromotion(promotion)}>
          <i className="material-icons blue-text">textsms</i>
        </a>) :
        (
          <p></p>
        )
        }
      </td>
      <td>
        { sent === false ?
        (<a href="#add-promotion-modal" className="waves-effect waves-light modal-trigger" onClick={() => setCurrentPromotion(promotion)}>
          <i className="material-icons teal-text">edit</i>
        </a>) : 
        (
          <p></p>
        )
        }
      </td>
      <td>
        <a href="#" className="waves-effect waves-light modal-trigger" onClick={onDelete}>
          <i className="material-icons red-text">delete</i>
        </a>
      </td>
    </tr>
  );
};

PromotionItem.propTypes = {
  promotion: PropTypes.object.isRequired
};

export default PromotionItem;
