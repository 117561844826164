import React, { useContext, useEffect, useState, Fragment } from 'react';
import AuthContext from '../../context/auth/authContext';
import PageContext from '../../context/page/pageContext';
import SettingContext from '../../context/setting/settingContext';
import AlertContext from '../../context/alert/alertContext';

const SettingsPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);
  const settingContext = useContext(SettingContext);
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;
  const { user } = authContext;
  const { error, updateSetting, bill_setting, updateBillSetting, clearSettingErrors } = settingContext;

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Settings");
    if (error) {
      setAlert("Setting was not updated!", "red");
    }
    if (bill_setting) {
      window.location.replace(bill_setting.url);
    }
    // eslint-disable-next-line
  }, [settingContext]);

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState({});
  const [autoCheckOut, setAutoCheckOut] = useState(0);
  const [googleReviewLink, setGoogleReviewLink] = useState("");
  const [facebookReviewLink, setFacebookReviewLink] = useState("");
  const [instagramReviewLink, setInstagramReviewLink] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const onChangePhone = e => setPhone(e.target.value);

  const onSubmitPhone = e => {
    e.preventDefault();
    updateSetting({_id: user._id, phone});
    setPhone("");
    clearSettingErrors();
  };

  const {street, city, state, zip, country} = address;

  const onChangeAddress = e =>
  setAddress({ ...address, [e.target.name]: e.target.value });

  const onSubmitAddress = e => {
    e.preventDefault();
    updateSetting({_id: user._id, address});
    setAddress({});
    clearSettingErrors();
  };

  const onChangeAutoCheckOut = e =>
  setAutoCheckOut(e.target.value);

  const onSubmitAutoCheckOut = e => {
    e.preventDefault();
    updateSetting({_id: user._id, autoCheckOut});
    clearSettingErrors();
  };

  const onChangeGoogleReviewLink = e => setGoogleReviewLink(e.target.value);
  const onChangeFacebookReviewLink = e => setFacebookReviewLink(e.target.value);
  const onChangeInstagramReviewLink = e => setInstagramReviewLink(e.target.value);

  const onSubmitReviewLink = e => {
    e.preventDefault();
    updateSetting({_id: user._id, googleReviewLink, facebookReviewLink, instagramReviewLink});
    setGoogleReviewLink("");
    setFacebookReviewLink("");
    setInstagramReviewLink("");
    clearSettingErrors();
  };

  const onChangeOldPassword = e => setOldPassword(e.target.value);
  const onChangeNewPassword = e => setNewPassword(e.target.value);
  const onChangeNewPassword2 = e => setNewPassword2(e.target.value);

  const onSubmitPassword = e => {
    e.preventDefault();
    if (newPassword === newPassword2) {
      updateSetting({_id: user._id, oldPassword, newPassword});
    } else {
      setAlert("Passwords did not match!", "red");
    }
    setOldPassword("");
    setNewPassword("");
    setNewPassword2("");
    clearSettingErrors();
  };

  // const onSubmitManageBilling = async e => {
  //   e.preventDefault();
  //   updateBillSetting(user._id);
  // }

  // phone input format
  let filter = [];

  const keypadZero = 48;
  const numpadZero = 96;

  for(var i = 0; i <= 9; i++){
    filter.push(i + keypadZero);
    filter.push(i + numpadZero);  
  }

  filter.push(8);//backspace
  filter.push(9);//tab
  filter.push(46);//delete
  filter.push(37);//left arrow
  filter.push(39);//right arrow

  const replaceAll = (src,search,replace) => {
    return src.split(search).join(replace);
  }

  const formatPhoneText = s => {
    let value = replaceAll(s.trim(),"-","");
    
    if(value.length > 3 && value.length <= 6) 
      value = value.slice(0,3) + "-" + value.slice(3);
    else if(value.length > 6) 
      value = value.slice(0,3) + "-" + value.slice(3,6) + "-" + value.slice(6);
    
    return value;
  }

  const validatePhone = p => {
    var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/;
    var digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
  }

  const onKeyDown = e => {  
    if(filter.indexOf(e.keyCode) < 0){
      e.preventDefault();
      return false;
    }  
  }

  const onKeyUp = e => {
    var input = e.target;
    var formatted = formatPhoneText(input.value);
    var isError = (validatePhone(formatted) || formatted.length === 0);
    var color =  (isError) ? "gray" : "red";
    var borderWidth =  (isError)? "1px" : "3px";
    input.style.borderColor = color;
    input.style.borderWidth = borderWidth;
    input.value = formatted;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col s12 m4">
          <span className="settings-title">Account</span>
          <p>Manage your account info, privacy, and security.</p>
        </div>
        <div className="col s12 m8">
        <div className="settings-group">
          <ul className="collapsible setting">
            <li>
              <div className="collapsible-header">
                <span>Username</span>
                <i className="material-icons caret right">keyboard_arrow_right</i>
              </div>
              <div className="collapsible-body">
                <div className="input-field">
                  <input placeholder="" type="text" value={user && user.email} className="grey-text"></input>
                  <label htmlFor="username">Username</label>
                </div>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                <span>Password</span>
                <i className="material-icons caret right">keyboard_arrow_right</i>
              </div>
              <div className="collapsible-body">
                <form onSubmit={onSubmitPassword}>
                <div className="input-field">
                  <input
                    type="password" 
                    placeholder=""
                    value={oldPassword}
                    onChange={onChangeOldPassword}>
                  </input>
                  <label htmlFor="password">Old Password</label>
                </div>
                <div className="input-field">
                  <input
                    type="password" 
                    placeholder=""
                    value={newPassword}
                    onChange={onChangeNewPassword}>
                  </input>
                  <label htmlFor="password">New Password</label>
                </div>
                <div className="input-field">
                  <input
                    type="password" 
                    placeholder=""
                    value={newPassword2}
                    onChange={onChangeNewPassword2}>
                  </input>
                  <label htmlFor="password">Confirm Password</label>
                </div>
                <div className="row">
                  <button
                    type="submit"
                    className="waves-effect waves-green btn-flat right">
                  <i className="material-icons right">send</i>Update
                  </button>
                </div>
                </form>
              </div>
            </li>
          </ul>
          <div className="setting">
          Agree to Terms And Policies?
          <label className="right">
            <input type="checkbox" className="filled-in" checked="checked"/>
            <span></span>
          </label>
        </div>
        </div>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m4">
          <span className="settings-title">Salon</span>
          <p>Organize your salon information so you can connect with customers on
            the platform better. </p>
        </div>
        <div className="col s12 m8">
        <div className="settings-group">
          <ul className="collapsible setting">
            <li>
              <div className="collapsible-header">
                <span>Salon Name</span>
                <i className="material-icons caret right">keyboard_arrow_right</i>
              </div>
              <div className="collapsible-body">
                <div className="input-field">
                  <input placeholder="" type="text" value={user && user.name} className="grey-text"></input>
                  <label htmlFor="salonname">Salon Name</label>
                </div>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                <span>Phone</span>
                <i className="material-icons caret right">keyboard_arrow_right</i>
              </div>
              <div className="collapsible-body">
                <form onSubmit={onSubmitPhone}>
                <label htmlFor="phone">Phone</label>
                <div className="input-field">
                  <input
                    type="text" 
                    placeholder=""
                    value={phone}
                    onChange={onChangePhone}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                  >
                  </input>
                  <label htmlFor="phone">{user && user.phone}</label>
                </div>
                <div className="row">
                  <button
                    type="submit"
                    className="waves-effect waves-green btn-flat right">
                  <i className="material-icons right">send</i>Update
                  </button>
                </div>
                </form>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                <span>Address</span>
                <i className="material-icons caret right">keyboard_arrow_right</i>
              </div>
              <div className="collapsible-body">
                <form onSubmit={onSubmitAddress}>
                <label htmlFor="address">Address</label>
                <div className="row">
                  <div className="input-field col s8">
                    <label>
                      {user && user.address && user.address.street}
                    </label>
                  </div>
                  <div className="input-field col s4">
                    <label> 
                      {user && user.address && user.address.city} 
                    </label>
                  </div>
                  <div className="input-field col s4">
                    <label>
                      {user && user.address && user.address.state}
                    </label>
                  </div>
                  <div className="input-field col s4">
                    <label> 
                      {user && user.address && user.address.zip}
                    </label>
                  </div>
                  <div className="input-field col s4">
                    <label> 
                      {user && user.address && user.address.country}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s8">
                    <input placeholder="" name="street" type="text" value={street} onChange={onChangeAddress}></input>
                    <label htmlFor="street">Street</label>
                  </div>
                  <div className="input-field col s4">
                    <input placeholder="" name="city" type="text" value={city} onChange={onChangeAddress}></input>
                    <label htmlFor="city">City</label>
                  </div>
                  <div className="input-field col s4">
                    <input placeholder="" name="state" type="text" value={state} onChange={onChangeAddress}></input>
                    <label htmlFor="state">State</label>
                  </div>
                  <div className="input-field col s4">
                    <input placeholder="" name="zip" type="number" value={zip} onChange={onChangeAddress}></input>
                    <label htmlFor="zip">Zip Code</label>
                  </div>
                  <div className="input-field col s4">
                    <input placeholder="" name="country" type="text" value={country} onChange={onChangeAddress}></input>
                    <label htmlFor="country">Country</label>
                  </div>
                </div>
                <div className="row">
                  <button
                    type="submit"
                    className="waves-effect waves-green btn-flat right">
                  <i className="material-icons right">send</i>Update
                  </button>
                </div>
                </form>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                <span>Review Links</span>
                <i className="material-icons caret right">keyboard_arrow_right</i>
              </div>
              <div className="collapsible-body">
                <form onSubmit={onSubmitReviewLink}>
                <label htmlFor="google-review-link">Google</label>
                <div className="input-field">
                  <input
                    type="text" 
                    placeholder=""
                    value={googleReviewLink}
                    onChange={onChangeGoogleReviewLink}>
                  </input>
                  <label htmlFor="google-review-link">{user && user.googleReviewLink}</label>
                </div>
                <label htmlFor="facebook-review-link">Facebook</label>
                <div className="input-field">
                  <input
                    type="text" 
                    placeholder=""
                    value={facebookReviewLink}
                    onChange={onChangeFacebookReviewLink}>
                  </input>
                  <label htmlFor="facebook-review-link">{user && user.facebookReviewLink}</label>
                </div>
                <label htmlFor="instagram-review-link">Instagram</label>
                <div className="input-field">
                  <input
                    type="text" 
                    placeholder=""
                    value={instagramReviewLink}
                    onChange={onChangeInstagramReviewLink}>
                  </input>
                  <label htmlFor="instagram-review-link">{user && user.instagramReviewLink}</label>
                </div>
                <div className="row">
                  <button
                    type="submit"
                    className="waves-effect waves-green btn-flat right">
                  <i className="material-icons right">send</i>Update
                  </button>
                </div>
                </form>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                <span>Auto Check Out</span>
                <i className="material-icons caret right">keyboard_arrow_right</i>
              </div>
              <div className="collapsible-body">
                <form onSubmit={onSubmitAutoCheckOut}>
                <div className="row">
                  <div className="input-field col s4">
                    <label htmlFor="autocheckout">Points given as auto checking out</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s4">
                    <label>
                      ${user && user.autoCheckOut}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s4">
                    <input placeholder="" name="autocheckout" type="number" value={autoCheckOut} onChange={onChangeAutoCheckOut}></input>
                  </div>
                </div>
                <div className="row">
                  <button
                    type="submit"
                    className="waves-effect waves-green btn-flat right">
                  <i className="material-icons right">send</i>Update
                  </button>
                </div>
                </form>
              </div>
            </li>
          </ul>
        </div>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m4">
          <span className="settings-title">Help</span>
          <p>How can we help you?</p>
        </div>
        <div className="col s12 m8">
        <div className="settings-group">
          <ul className="collapsible setting">
            <li>
              <div className="collapsible-header">
                <span>Contact</span>
                <i className="material-icons caret right">keyboard_arrow_right</i>
              </div>
              <div className="collapsible-body">
                <div className="input-field">
                  <p className="grey-text">
                    You can call us at (206) 816-4872 for support
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="collapsible-header">
                <span>More About Us</span>
                <i className="material-icons caret right">keyboard_arrow_right</i>
              </div>
              <div className="collapsible-body">
                <div className="input-field">
                  <p className="grey-text">
                    Our mission is to help businesses delight the customer by building 
                    a long lasting relationship. Our digital platform allows the customer
                    to review, connect, and have a present voice in their community's 
                    favorite local business
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SettingsPage;
