import React, { useContext, useEffect, useState, useRef } from 'react';
import AuthContext from '../../context/auth/authContext';
import AddPromotionBtn from '../promotions/AddPromotionBtn';
import Promotions from '../promotions/Promotions';
import PromotionForm from '../promotions/PromotionForm';
import PromotionTextForm from '../promotions/PromotionTextForm';
import PageContext from '../../context/page/pageContext';

const PromotionsPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Promotions")
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row">
      <AddPromotionBtn />
      <PromotionForm />
      <PromotionTextForm />
      <div className="card">
        <div className="row">
          <Promotions />
        </div>
      </div>
    </div>
  );
};

export default PromotionsPage;
