import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import StaffContext from '../../context/staff/staffContext';
//import Avatar from 'react-avatar';

const StaffItem = ({ staff }) => {
  const staffContext = useContext(StaffContext);
  
  const { deleteStaff, setCurrentStaff, clearCurrentStaff, setCurrentShift  } = staffContext;

  const { _id, name, shiftStart, shiftEnd } = staff;

  const onDelete = () => {
    deleteStaff(_id);
    clearCurrentStaff();
  };

  const onClickShift = (shift) => {
    setCurrentStaff(staff);
    setCurrentShift(shift);
  };

  const displayShift = (shift) => {
    let sValue = "";
    let hour = parseInt(shift.split(":")[0]);
    let minute = parseInt(shift.split(":")[1]);
    if (hour >= 12) {
      hour = hour - 12;
      sValue = hour.toString(10) + ":" + minute.toString(10) + "pm";
    } else {
      sValue = hour.toString(10) + ":" + minute.toString(10) + "am";
    }

    return sValue;
  }

  return (
    <tr role="row">
      <td><a href="#staff-modal" className="modal-trigger black-text" onClick={() => setCurrentStaff(staff)}>{name}</a></td>
      { (shiftStart[0] !== "" && shiftEnd[0] !== "") ?
      (<td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(0); }}>
          {displayShift(shiftStart[0])}-{displayShift(shiftEnd[0])}
        </a>
        </td>) :
      (
      <td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(0); }}>
          ---
        </a>
      </td>
      )
      }
      { (shiftStart[1] !== "" && shiftEnd[1] !== "") ?
      (<td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(1); }}>
          {displayShift(shiftStart[1])}-{displayShift(shiftEnd[1])}
        </a>
        </td>) :
      (
      <td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(1); }}>
          ---
        </a>
      </td>
      )
      }
      { (shiftStart[2] !== "" && shiftEnd[2] !== "") ?
      (<td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(2); }}>
          {displayShift(shiftStart[2])}-{displayShift(shiftEnd[2])}
        </a>
        </td>) :
      (
      <td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(2); }}>
          ---
        </a>
      </td>
      )
      }
      { (shiftStart[3] !== "" && shiftEnd[3] !== "") ?
      (<td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(3); }}>
          {displayShift(shiftStart[3])}-{displayShift(shiftEnd[3])}
        </a>
        </td>) :
      (
      <td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(3); }}>
          ---
        </a>
      </td>
      )
      }
      { (shiftStart[4] !== "" && shiftEnd[4] !== "") ?
      (<td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(4); }}>
          {displayShift(shiftStart[4])}-{displayShift(shiftEnd[4])}
        </a>
        </td>) :
      (
      <td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(4); }}>
          ---
        </a>
      </td>
      )
      }
      { (shiftStart[5] !== "" && shiftEnd[5] !== "") ?
      (<td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(5); }}>
          {displayShift(shiftStart[5])}-{displayShift(shiftEnd[5])}
        </a>
        </td>) :
      (
      <td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(5); }}>
          ---
        </a>
      </td>
      )
      }
      { (shiftStart[6] !== "" && shiftEnd[6] !== "") ?
      (<td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(6); }}>
          {displayShift(shiftStart[6])}-{displayShift(shiftEnd[6])}
        </a>
        </td>) :
      (
      <td>
        <a href="#staff-date-modal" className="modal-trigger black-text" onClick={() => { onClickShift(6); }}>
          ---
        </a>
      </td>
      )
      }
      <td>
        <a href="#" className="waves-effect waves-light" onClick={onDelete}>
          <i className="material-icons red-text">delete</i>
        </a>
      </td>
    </tr>
  );
};

StaffItem.propTypes = {
  staff: PropTypes.object.isRequired
};

export default StaffItem;
