import React, { useContext, useEffect } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // need for dayclick
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';

import AuthContext from '../../context/auth/authContext';
import PageContext from '../../context/page/pageContext';
import AppointmentContext from '../../context/appointment/appointmentContext';
import AddAppointmentBtn from '../appointments/AddAppointmentBtn';
import AppointmentForm from '../appointments/AppointmentForm';

import '@fortawesome/fontawesome-free/css/all.css';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/bootstrap/main.css';

const CalendarPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);
  const appointmentContext = useContext(AppointmentContext);

  const { appointments, getAppointments } = appointmentContext;

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Calendar");
    getAppointments();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row">
      <AddAppointmentBtn />
      <AppointmentForm />
      <div className="card">
        <FullCalendar 
          defaultView="listDay" 
          plugins={[ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin ]}
          events={appointments}
          slotDuration="00:15:00"
          minTime="09:00:00"
          maxTime="22:00:00"
          themeSystem="bootstrap"
          header={{
            left: 'prev,next,today',
            center: 'title',
            right: 'listDay,dayGridWeek'
          }}
          buttonIcons={{
            close: 'fa-times',
            prev: 'fa-chevron-left',
            next: 'fa-chevron-right',
            prevYear: 'fa-angle-double-left',
            nextYear: 'fa-angle-double-right',
          }}
        />
      </div>
    </div>
  );
}

export default CalendarPage;
