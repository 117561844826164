import React, { useState, useContext, useEffect } from 'react';
import StaffContext from '../../context/staff/staffContext';

const StaffDateForm = () => {
  const staffContext = useContext(StaffContext);

  const { updateStaff, currentStaff, clearCurrentStaff, currentShift } = staffContext;

  useEffect(() => {
    if (currentStaff !== null) {
      setStaff(currentStaff);
    } else {
      setStaff({
        name: '',
        shiftStart: ["","","","","","",""],
        shiftEnd: ["","","","","","",""]
      });
    }
  }, [currentStaff]);

  const [staff, setStaff] = useState({
    name: '',
    shiftStart: ["","","","","","",""],
    shiftEnd: ["","","","","","",""]
  });

  const { name, shiftStart, shiftEnd } = staff;

  const onChangeStart = e => {
    let updatedShiftStart = shiftStart;
    updatedShiftStart[currentShift] = e.target.value;
    setStaff({...staff, shiftStart: updatedShiftStart});
  }

  const onChangeEnd = e => {
    let updatedShiftEnd = shiftEnd;
    updatedShiftEnd[currentShift] = e.target.value;
    setStaff({...staff, shiftEnd: updatedShiftEnd});
  }

  const onSubmit = e => {
    e.preventDefault();
    updateStaff(staff);
    clearAll();
  };

  const clearAll = () => {
    clearCurrentStaff();
  };

  return (
    <div id="staff-date-modal" className="modal">
      <form onSubmit={onSubmit}>
      <div className="modal-content">
        <h6 className="center-align">
          {name}'s Working Hours
        </h6>
        <label htmlFor="shift-start">Shift Start</label>
        <input
          type="time"
          name="shift-start"
          value={shiftStart[currentShift]}
          onChange={onChangeStart}
        />
        <label htmlFor="shift-end">Shift End</label>
        <input
          type="time"
          name="shift-end"
          value={shiftEnd[currentShift]}
          onChange={onChangeEnd}
        />
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="modal-close waves-effect waves-green btn-flat"><i className="material-icons right">add</i>Add Shift
        </button>
      </div>
      </form>
    </div>
  );
};

export default StaffDateForm;
