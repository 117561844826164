import React, { useState, useContext, useEffect } from 'react';
import ServiceContext from '../../context/service/serviceContext';

const ServiceForm = () => {
  const serviceContext = useContext(ServiceContext);

  const { addService, updateService, clearCurrentService, currentService } = serviceContext;

  useEffect(() => {
    if (currentService !== null) {
      setService(currentService);
    } else {
      setService({
        name: '',
        duration: '',
        price: 0,
      });
    }
  }, [serviceContext, currentService]);

  const [service, setService] = useState({
    name: '',
    duration: '',
    price: 0,
  });

  //const { name, duration, price } = service;

  const onChange = e =>
    setService({ ...service, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (currentService === null) {
      addService(service);
    } else {
      updateService(service);
    }
    clearAll();
  };

  const clearAll = () => {
    clearCurrentService();
  };

  return (
    <div id="add-service-modal" className="modal">
      <form onSubmit={onSubmit}>
      <div className="modal-content">
        <h6 className="center-align">
          {currentService ? 'Edit Service' : 'Add Service'}
        </h6>
        <div className="input-field">
          <label htmlFor="name">Service Name</label>
          <input
            type="text"
            placeholder=""
            name="name"
            onChange={onChange}
          />
        </div>
        <div className="input-field">
          <label htmlFor="duration">Duration</label>
          <input
            type="number"
            placeholder="in minutes"
            name="duration"
            onChange={onChange}
          />
        </div>
        <div className="input-field">
          <label htmlFor="price">Price</label>
          <input
            type="number"
            placeholder="in dollars"
            name="price"
            onChange={onChange}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="modal-close waves-effect waves-green btn-flat"><i className="material-icons right">{currentService ? "edit" : "add"}</i>{currentService ? "Edit Service" : "Add Service"}
        </button>
      </div>
      </form>
    </div>
  );
};

export default ServiceForm;
