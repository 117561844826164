import React, { useReducer } from 'react';
import axios from 'axios';
import RewardContext from './rewardContext';
import rewardReducer from './rewardReducer';
import {
  GET_REWARDS,
  ADD_REWARD,
  DELETE_REWARD,
  SET_CURRENT_REWARD,
  CLEAR_CURRENT_REWARD,
  UPDATE_REWARD,
  CLEAR_REWARDS,
  REWARD_ERROR
} from '../types';

const RewardState = props => {
  const initialState = {
    rewards: null,
    currentReward: null,
    error: null
  };

  const [state, dispatch] = useReducer(rewardReducer, initialState);

  // Get Rewards
  const getRewards = async () => {
    try {
      const res = await axios.get('/api/rewards');

      dispatch({
        type: GET_REWARDS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: REWARD_ERROR,
        payload: err.response.data
      });
    }
  };

  // Add Reward
  const addReward = async reward => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.post('/api/rewards', reward, config);

      dispatch({
        type: ADD_REWARD,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: REWARD_ERROR,
        payload: err.response.data
      });
    }
  };

  // Delete Reward
  const deleteReward = async id => {
    try {
      await axios.delete(`/api/rewards/${id}`);

      dispatch({
        type: DELETE_REWARD,
        payload: id
      });
    } catch (err) {
      dispatch({
        type: REWARD_ERROR,
        payload: err.response.data
      });
    }
  };

  // Update Reward
  const updateReward = async reward => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.put(
        `/api/rewards/${reward._id}`,
        reward,
        config
      );

      dispatch({
        type: UPDATE_REWARD,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: REWARD_ERROR,
        payload: err.response.data
      });
    }
  };

  // Clear Rewards
  const clearRewards = () => {
    dispatch({ type: CLEAR_REWARDS });
  };

  // Set Current Reward
  const setCurrentReward = reward => {
    dispatch({ type: SET_CURRENT_REWARD, payload: reward });
  };

  // Clear Current Reward
  const clearCurrentReward = () => {
    dispatch({ type: CLEAR_CURRENT_REWARD });
  };

  return (
    <RewardContext.Provider
      value={{
        rewards: state.rewards,
        currentReward: state.currentReward,
        error: state.error,
        addReward,
        deleteReward,
        setCurrentReward,
        clearCurrentReward,
        updateReward,
        getRewards,
        clearRewards
      }}
    >
      {props.children}
    </RewardContext.Provider>
  );
};

export default RewardState;
