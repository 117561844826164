import React, { useContext, useEffect, useState, useRef } from 'react';
import AuthContext from '../../context/auth/authContext';
import AddReminderBtn from '../reminders/AddReminderBtn';
import Reminders from '../reminders/Reminders';
import ReminderForm from '../reminders/ReminderForm';
import PageContext from '../../context/page/pageContext';

const RemindersPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Reminders")
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row">
      <AddReminderBtn />
      <ReminderForm />
      <div className="card">
        <div className="row">
          <Reminders />
        </div>
      </div>
    </div>
  );
};

export default RemindersPage;
