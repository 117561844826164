import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReviewContext from '../../context/review/reviewContext';
import moment from "moment";

import StarRating from 'react-star-rating-component';

const ReviewItem = ({ review }) => {
  const reviewContext = useContext(ReviewContext);
  const { setCurrentReview } = reviewContext;

  const { comment, rating, createdAt } = review;

  return (
    <tr role="row">
      <td><StarRating name={"null"} value={rating} starColor={"#b2dfdb"} editing={false} renderStarIcon={() => <i className="material-icons">star</i>}/></td>
      <td>{comment.slice(0,32)}...</td>
      <td>{moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        <a href="#review-details-modal" className="waves-effect waves-light modal-trigger" onClick={() => setCurrentReview(review)}>
          <i className="material-icons grey-text">more_horiz</i>
        </a>
      </td>
    </tr>
  );
};

ReviewItem.propTypes = {
  review: PropTypes.object.isRequired
};

export default ReviewItem;
