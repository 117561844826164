import React, { useContext } from 'react';
import PromotionContext from '../../context/promotion/promotionContext';

const AddPromotionBtn = () => {
  const promotionContext = useContext(PromotionContext);

  const { clearCurrentPromotion } = promotionContext;

  const clearAll = () => {
    clearCurrentPromotion();
  };

  return (
    <div className="fixed-action-btn">
      <a href="#add-promotion-modal" className="btn-floating btn-large teal modal-trigger" onClick={clearAll}>
        <i className="large material-icons">add</i>
      </a>
    </div>
  )
}

export default AddPromotionBtn;