import {
  GET_REMINDERS,
  ADD_REMINDER,
  DELETE_REMINDER,
  SET_CURRENT_REMINDER,
  CLEAR_CURRENT_REMINDER,
  UPDATE_REMINDER,
  REMINDER_ERROR,
  CLEAR_REMINDERS
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_REMINDERS:
      return {
        ...state,
        reminders: action.payload,
        loading: false
      };
    case ADD_REMINDER:
      return {
        ...state,
        reminders: [action.payload, ...state.reminders],
        loading: false
      };
    case UPDATE_REMINDER:
      return {
        ...state,
        reminders: state.reminders.map(reminder =>
          reminder._id === action.payload._id ? action.payload : reminder
        ),
        loading: false
      };
    case DELETE_REMINDER:
      return {
        ...state,
        reminders: state.reminders.filter(
          reminder => reminder._id !== action.payload
        ),
        loading: false
      };
    case CLEAR_REMINDERS:
      return {
        ...state,
        reminders: null,
        error: null,
        currentReminder: null
      };
    case SET_CURRENT_REMINDER:
      return {
        ...state,
        currentReminder: action.payload
      };
    case CLEAR_CURRENT_REMINDER:
      return {
        ...state,
        currentReminder: null
      };
    case REMINDER_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
