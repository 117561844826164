import React, { useContext } from 'react';
import ServiceContext from '../../context/service/serviceContext';

const AddServiceBtn = () => {
  const serviceContext = useContext(ServiceContext);

  const { clearCurrentService } = serviceContext;

  const clearAll = () => {
    clearCurrentService();
  };

  return (
    <div className="fixed-action-btn">
      <a href="#add-service-modal" className="btn-floating btn-large teal modal-trigger" onClick={clearAll}>
        <i className="large material-icons">add</i>
      </a>
    </div>
  )
}

export default AddServiceBtn;