import React, { useReducer } from 'react';
import axios from 'axios';
import CustomerContext from './customerContext';
import customerReducer from './customerReducer';
import {
  GET_CUSTOMERS,
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  SET_CURRENT_CUSTOMER,
  CLEAR_CURRENT_CUSTOMER,
  UPDATE_CUSTOMER,
  FILTER_CUSTOMERS,
  CLEAR_CUSTOMERS,
  CLEAR_FILTER_CUSTOMERS,
  CUSTOMER_ERROR
} from '../types';

const CustomerState = props => {
  const initialState = {
    customers: null,
    currentCustomer: null,
    filteredCustomers: null,
    error: null
  };

  const [state, dispatch] = useReducer(customerReducer, initialState);

  // Get Customers
  const getCustomers = async () => {
    try {
      const res = await axios.get('/api/customers');

      dispatch({
        type: GET_CUSTOMERS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: CUSTOMER_ERROR,
        payload: err.response.data
      });
    }
  };

  // Add Customer
  const addCustomer = async customer => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.post('/api/customers', customer, config);

      dispatch({
        type: ADD_CUSTOMER,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: CUSTOMER_ERROR,
        payload: err.response.data
      });
    }
  };

  // Delete Customer
  const deleteCustomer = async id => {
    try {
      await axios.delete(`/api/customers/${id}`);

      dispatch({
        type: DELETE_CUSTOMER,
        payload: id
      });
    } catch (err) {
      dispatch({
        type: CUSTOMER_ERROR,
        payload: err.response.data
      });
    }
  };

  // Update Customer
  const updateCustomer = async customer => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.put(
        `/api/customers/${customer._id}`,
        customer,
        config
      );

      dispatch({
        type: UPDATE_CUSTOMER,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: CUSTOMER_ERROR,
        payload: err.response.data
      });
    }
  };

  // Clear Customers
  const clearCustomers = () => {
    dispatch({ type: CLEAR_CUSTOMERS });
  };

  // Set Current Customer
  const setCurrentCustomer = customer => {
    dispatch({ type: SET_CURRENT_CUSTOMER, payload: customer });
  };

  // Clear Current Customer
  const clearCurrentCustomer = () => {
    dispatch({ type: CLEAR_CURRENT_CUSTOMER });
  };

  // Filter Customers
  const filterCustomers = text => {
    dispatch({ type: FILTER_CUSTOMERS, payload: text });
  };

  // Clear Filter
  const clearFilterCustomers = () => {
    dispatch({ type: CLEAR_FILTER_CUSTOMERS });
  };

  return (
    <CustomerContext.Provider
      value={{
        customers: state.customers,
        currentCustomer: state.currentCustomer,
        filteredCustomers: state.filteredCustomers,
        error: state.error,
        addCustomer,
        deleteCustomer,
        setCurrentCustomer,
        clearCurrentCustomer,
        updateCustomer,
        filterCustomers,
        clearFilterCustomers,
        getCustomers,
        clearCustomers
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  );
};

export default CustomerState;
