import React, { useContext, useEffect, useState, useRef } from 'react';
import AuthContext from '../../context/auth/authContext';
import AddCustomerBtn from '../customers/AddCustomerBtn';
import Customers from '../customers/Customers';
import CustomerForm from '../customers/CustomerForm';
import CustomerFilter from '../customers/CustomerFilter';
import PageContext from '../../context/page/pageContext';

const CustomersPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Customers");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row">
      <AddCustomerBtn />
      <CustomerForm />
      <div className="card">
        <div className="row">
          <div className="col right">
            <CustomerFilter />
          </div>
        </div>
        <div className="row">
          <Customers />
        </div>
      </div>
    </div>
  );
};

export default CustomersPage;
