import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import Checkouts from '../checkouts/Checkouts';
import CheckoutForm from '../checkouts/CheckoutForm';
import RefreshCheckoutBtn from '../checkouts/RefreshCheckoutBtn';
import PageContext from '../../context/page/pageContext';

const CheckoutsPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Check Out")
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row">
      <RefreshCheckoutBtn />
      <CheckoutForm />
      <div className="card">
        <div className="row">
          <Checkouts />
        </div>
      </div>
    </div>
  );
};

export default CheckoutsPage;
