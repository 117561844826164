import React, { useState, useContext, useEffect, Fragment } from 'react';
import PageContext from '../../context/page/pageContext';
import ReviewContext from '../../context/review/reviewContext';
import { TransitionGroup } from 'react-transition-group';
import Spinner from '../layout/Spinner';
import AlertContext from '../../context/alert/alertContext';

import '@fortawesome/fontawesome-free/css/fontawesome.min.css';

const FeedbacksPage = () => {
  const pageContext = useContext(PageContext);
  const alertContext = useContext(AlertContext);
  const reviewContext = useContext(ReviewContext);
  
  const { setAlert } = alertContext;
  const { currentReview, getCurrentReview, updateReview, clearCurrentReview, loading } = reviewContext;

  const [comment, setComment] = useState("");

  useEffect(() => {
    pageContext.selectPage("Feedbacks");
    const id = window.location.pathname.replace("/feedbacks/", "");
    getCurrentReview(id);
  }, []);

  const onChangeComment = e => setComment(e.target.value);

  const onSubmitReview = e => {
    const rating = parseInt(e.target.id);
    const id = window.location.pathname.replace("/feedbacks/", "");
    updateReview({ _id: id, comment, rating });
    clearCurrentReview();
    setAlert(`Thank you so much for your feedback! Reloading...`, 'green', 3000);
    setTimeout(() => { window.location.reload(true); }, 3000);
  };

  const notReviewed = (
    <Fragment>
      <div className="card">
        <div className="card-content">
          <div className="row">
            <p>We'd love to learn more about your experience with {currentReview && currentReview.salonName} today,
              your feedback helps us alot to improve our service and it only takes few minutes.
            </p>
          </div>
          <div className="row">
            <p>Thank you so much!</p>
          </div>
          <div className="row">
            <form>
              <div className="input-field col s12">
                <textarea name="comment" className="materialize-textarea" maxLength="100" onChange={onChangeComment}></textarea>
                <label htmlFor="comment">Click here to leave your feedback</label>
              </div>
            </form>
          </div>
        </div>
        <div className="card-action">
          <div className="row center">
            <p>What is your overall experience with {currentReview && currentReview.salonName} today?</p>
          </div>
          <div className="row center">
            <div className="col s4">
              <a href="#"><i className="medium red-text material-icons" id="1" onClick={onSubmitReview}>sentiment_very_dissatisfied</i></a>
            </div>
            <div className="col s4">
              <a href="#"><i className="medium red-text text-lighten-2 material-icons" id="2" onClick={onSubmitReview}>sentiment_dissatisfied</i></a>
            </div>
            <div className="col s4">
              <a href="#"><i className="medium orange-text material-icons" id="3" onClick={onSubmitReview}>sentiment_neutral</i></a>
            </div>
          </div>
          <div className="row center">
            <div className="col s2"></div>
            <div className="col s4">
              <a href="#"><i className="medium teal-text text-lighten-2 material-icons" id="4" onClick={onSubmitReview}>sentiment_satisfied</i></a>
            </div>
            <div className="col s3">
              <a href="#"><i className="medium teal-text material-icons" id="5" onClick={onSubmitReview}>sentiment_very_satisfied</i></a>
            </div>
            <div className="col s3"><span>Very Statisfied</span></div>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const reviewed = (
    <div className="card">
      <div className="card-content">
        <div className="row center">
          <span className="card-title center teal-text text-darken-2">
            {currentReview && currentReview.salonName}
          </span>
          <span>
            {currentReview && currentReview.salonAddress && currentReview.salonAddress.street}, {currentReview && currentReview.salonAddress && currentReview.salonAddress.city}, {currentReview && currentReview.salonAddress && currentReview.salonAddress.state}, {currentReview && currentReview.salonAddress && currentReview.salonAddress.zip}
          </span>
          <a href={currentReview && ("tel:" + currentReview.salonPhone)}><p className="blue-text">{currentReview && currentReview.salonPhone}</p></a>
        </div>
        <div className="row center">
          <p>Thank you so much for your feedback, your feedback helps us alot to improve our service!</p>
        </div>
        {(currentReview && currentReview.rating > 3) ? (
        <Fragment>
        <div className="row center">
          <p>
            Reviews help our business build an online presence and help others learn about us.
            You can help us by clicking one of these links to write an online review.
          </p>
        </div>
        {currentReview && currentReview.googleReviewLink !=="" &&
        <div className="row center">
          <a href={currentReview && currentReview.googleReviewLink}><i className="fab fa-google-plus-square fa-lg yellow-text text-darken-3"> Review us on Google</i></a>
        </div>
        }
        {currentReview && currentReview.facebookReviewLink !== "" &&
        <div className="row center">
          <a href={currentReview && currentReview.facebookReviewLink}><i className="fab fa-facebook-square fa-lg light-blue-text text-accent-3"> Review us on Facebook</i></a>
        </div>
        }
        {currentReview && currentReview.instagramReviewLink !== "" &&
        <div className="row center">
          <a href={currentReview && currentReview.instagramReviewLink}><i className="fab fa-instagram-square fa-lg pink-text text-accent-2"> Review us on Instagram</i></a>
        </div>
        }
        </Fragment>
        ) : ((currentReview && currentReview.rating < 3) ? ( 
        <Fragment>
        <div className="row center">
          <p>
            We're very sorry to hear that you had a bad experience! 
            It's our goal to deliver the highest quality of service to all of our customers.
            Please feel free to contact us so that we can be of any assistance.
          </p>
        </div>
        </Fragment>
        ) : (
        <Fragment></Fragment>
        ))}
      </div>
    </div>
  );

  return (
    <div className="row">
      <div className="col s12">
        {(currentReview && currentReview.salonName && !loading) ? 
          (<TransitionGroup>
            {(currentReview.rating !== 0) ? reviewed : notReviewed}
          </TransitionGroup>) :
          (<Spinner />)}
      </div>
    </div>
  );
};

export default FeedbacksPage;
