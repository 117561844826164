import React, { useReducer } from 'react';
import axios from 'axios';
import StaffContext from './staffContext';
import staffReducer from './staffReducer';
import {
  GET_STAFFS,
  ADD_STAFF,
  DELETE_STAFF,
  SET_CURRENT_STAFF,
  SET_CURRENT_SHIFT,
  CLEAR_CURRENT_STAFF,
  UPDATE_STAFF,
  CLEAR_STAFFS,
  STAFF_ERROR
} from '../types';

const StaffState = props => {
  const initialState = {
    staffs: null,
    currentStaff: null,
    currentShift: 0,
    error: null
  };

  const [state, dispatch] = useReducer(staffReducer, initialState);

  // Get Staffs
  const getStaffs = async () => {
    try {
      const res = await axios.get('/api/staffs');

      dispatch({
        type: GET_STAFFS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: STAFF_ERROR,
        payload: err.response.data
      });
    }
  };

  // Add Staff
  const addStaff = async staff => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.post('/api/staffs', staff, config);

      dispatch({
        type: ADD_STAFF,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: STAFF_ERROR,
        payload: err.response.data
      });
    }
  };

  // Delete Staff
  const deleteStaff = async id => {
    try {
      await axios.delete(`/api/staffs/${id}`);

      dispatch({
        type: DELETE_STAFF,
        payload: id
      });
    } catch (err) {
      dispatch({
        type: STAFF_ERROR,
        payload: err.response.data
      });
    }
  };

  // Update Staff
  const updateStaff = async staff => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.put(
        `/api/staffs/${staff._id}`,
        staff,
        config
      );

      dispatch({
        type: UPDATE_STAFF,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: STAFF_ERROR,
        payload: err.response.data
      });
    }
  };

  // Clear Staffs
  const clearStaffs = () => {
    dispatch({ type: CLEAR_STAFFS });
  };

  // Set Current Staff
  const setCurrentStaff = staff => {
    dispatch({ type: SET_CURRENT_STAFF, payload: staff });
  };

  // Set Current Shift
  const setCurrentShift = shift => {
    dispatch({ type: SET_CURRENT_SHIFT, payload: shift });
  };

  // Clear Current Staff
  const clearCurrentStaff = () => {
    dispatch({ type: CLEAR_CURRENT_STAFF });
  };

  return (
    <StaffContext.Provider
      value={{
        staffs: state.staffs,
        currentStaff: state.currentStaff,
        currentShift: state.currentShift,
        error: state.error,
        addStaff,
        deleteStaff,
        setCurrentStaff,
        setCurrentShift,
        clearCurrentStaff,
        updateStaff,
        getStaffs,
        clearStaffs
      }}
    >
      {props.children}
    </StaffContext.Provider>
  );
};

export default StaffState;
