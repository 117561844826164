import React, { Fragment, useContext, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ReminderItem from './ReminderItem';
import Spinner from '../layout/Spinner';
import ReminderContext from '../../context/reminder/reminderContext';

const Reminders = () => {
  const reminderContext = useContext(ReminderContext);

  const { reminders, getReminders, loading } = reminderContext;

  useEffect(() => {
    getReminders();
    // eslint-disable-next-line
  }, []);

  if (reminders !== null && reminders.length === 0 && !loading) {
    return (
    <Fragment>
      <table className="border highlight centered responsive-table table-status-sheet" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th>Remind After</th>
            <th>Valid In</th>
            <th>Discount</th>
            <th>Status</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody></tbody>
        <tbody></tbody>
      </table>
    </Fragment>
    );
  }

  return (
    <Fragment>
      {reminders !== null && !loading ? (
        <TransitionGroup>
          <table className="border highlight centered responsive-table table-status-sheet" cellSpacing="0" width="100%">
          <thead>
            <tr>
              <th>Remind After</th>
              <th>Valid In</th>
              <th>Discount</th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {reminders.map(reminder => (
            <CSSTransition
              key={reminder._id}
              timeout={500}
            >
              <ReminderItem reminder={reminder} />
            </CSSTransition>
          ))}
          </tbody>
          </table>
        </TransitionGroup>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default Reminders;
