import React, { Fragment, useContext, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import StaffItem from './StaffItem';
import Spinner from '../layout/Spinner';
import StaffContext from '../../context/staff/staffContext';

const Staffs = () => {
  const staffContext = useContext(StaffContext);

  const { staffs, getStaffs, loading } = staffContext;

  useEffect(() => {
    getStaffs();
    // eslint-disable-next-line
  }, []);

  if (staffs !== null && staffs.length === 0 && !loading) {
    return (
    <Fragment>
      <table className="border highlight centered responsive-table" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th>Staff</th>
            <th>Monday</th>
            <th>Tuesday</th>
            <th>Wednesday</th>
            <th>Thursday</th>
            <th>Friday</th>
            <th>Saturday</th>
            <th>Sunday</th>
            <th></th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </Fragment>
    );
  }

  return (
    <Fragment>
      {staffs !== null && !loading ? (
        <TransitionGroup>
          <table className="border highlight centered responsive-table" cellSpacing="0" width="100%">
          <thead>
            <tr>
              <th>Staff</th>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
              <th>Sunday</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {staffs.map(staff => (
            <CSSTransition
              key={staff._id}
              timeout={500}
            >
              <StaffItem staff={staff} />
            </CSSTransition>
          ))}
          </tbody>
          </table>
        </TransitionGroup>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default Staffs;
