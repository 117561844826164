import React, { useContext, useRef, useEffect } from 'react';
import CustomerContext from '../../context/customer/customerContext';

const CustomerFilter = () => {
  const customerContext = useContext(CustomerContext);
  const text = useRef('');

  const { filterCustomers, clearFilterCustomers, filteredCustomers } = customerContext;

  useEffect(() => {
    if (filteredCustomers === null) {
      text.current.value = '';
    }
  });

  const onChange = e => {
    if (text.current.value !== '') {
      filterCustomers(e.target.value);
    } else {
      clearFilterCustomers();
    }
  };

  return (
    <form>
      <div className="input-field">
      <i className="material-icons prefix">search</i>
      <input
        id="search"
        ref={text}
        type='text'
        onChange={onChange}
        size="32"
      />
      <label htmlFor="search">Search by name, phone, or visits</label>
      </div>
    </form>
  );
};

export default CustomerFilter;
