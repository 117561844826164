import React, { useState, useContext, useEffect } from 'react';
import CustomerContext from '../../context/customer/customerContext';

const CustomerForm = () => {
  const customerContext = useContext(CustomerContext);

  const { addCustomer, updateCustomer, clearCurrentCustomer, currentCustomer } = customerContext;

  useEffect(() => {
    if (currentCustomer !== null) {
      setCustomer(currentCustomer);
    } else {
      setCustomer({
        name: '',
        phone: '',
        points: 0
      });
    }
  }, [customerContext, currentCustomer]);

  const [customer, setCustomer] = useState({
    name: '',
    phone: '',
    points: 0
  });

  const { name, phone, points } = customer;

  const onChange = e =>
    setCustomer({ ...customer, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (currentCustomer === null) {
      addCustomer(customer);
    } else {
      updateCustomer(customer);
    }
    clearAll();
  };

  const clearAll = () => {
    clearCurrentCustomer();
  };

  // phone input format
  let filter = [];

  const keypadZero = 48;
  const numpadZero = 96;
  
  for(var i = 0; i <= 9; i++){
    filter.push(i + keypadZero);
    filter.push(i + numpadZero);  
  }
  
  filter.push(8);//backspace
  filter.push(9);//tab
  filter.push(46);//delete
  filter.push(37);//left arrow
  filter.push(39);//right arrow
  
  const replaceAll = (src,search,replace) => {
    return src.split(search).join(replace);
  }
  
  const formatPhoneText = s => {
    let value = replaceAll(s.trim(),"-","");
    
    if(value.length > 3 && value.length <= 6) 
      value = value.slice(0,3) + "-" + value.slice(3);
    else if(value.length > 6) 
      value = value.slice(0,3) + "-" + value.slice(3,6) + "-" + value.slice(6);
    
    return value;
  }
  
  const validatePhone = p => {
    var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/;
    var digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
  }
  
  const onKeyDown = e => {  
    if(filter.indexOf(e.keyCode) < 0){
      e.preventDefault();
      return false;
    }  
  }
  
  const onKeyUp = e => {
    var input = e.target;
    var formatted = formatPhoneText(input.value);
    var isError = (validatePhone(formatted) || formatted.length === 0);
    var color =  (isError) ? "gray" : "red";
    var borderWidth =  (isError)? "1px" : "3px";
    input.style.borderColor = color;
    input.style.borderWidth = borderWidth;
    input.value = formatted;
  }

  return (
    <div id="add-customer-modal" className="modal">
      <form onSubmit={onSubmit}>
      <div className="modal-content">
        <h6 className="center-align">
          {currentCustomer ? 'Edit Customer' : 'Add Customer'}
        </h6>
        <div className="input-field">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            placeholder=""
            name="name"
            value={name}
            onChange={onChange}
          />
        </div>
        <div className="input-field">
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            placeholder=""
            name="phone"
            value={phone}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />
        </div>
        <div className="input-field">
          <label htmlFor="points">Points</label>
          <input
            type="number"
            name="points"
            value={points}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="modal-close btn-large btn-flat"><i className="material-icons right">{currentCustomer ? "edit" : "add"}</i>{currentCustomer ? "Edit Customer" : "Add Customer"}
        </button>
      </div>
      </form>
    </div>
  );
};

export default CustomerForm;
