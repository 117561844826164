import React, { useState, useContext, useEffect, useRef, Fragment } from 'react';
import CheckinContext from '../../context/checkin/checkinContext';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import SignatureCanvas from 'react-signature-canvas';

import M from 'materialize-css/dist/js/materialize.min.js';
import giftopen from './giftopen.gif';
import "../../App.css";

import RewardContext from '../../context/reward/rewardContext';

const CheckinForm = () => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const checkinContext = useContext(CheckinContext);
  const rewardContext = useContext(RewardContext);

  const { setAlert } = alertContext;
  const { updateCheckin, clearCurrentCheckin, currentCheckin, checkinMsg, clearCheckinMsg, addCheckin, redeemCheckin, uploadSignature } = checkinContext;
  const { user } = authContext;

  const { rewards, getRewards } = rewardContext;

  const [reward, setReward] = useState({
    points: 0,
    discount: 0,
    type: '$'
  });

  const checkRewards = (visits, points) => {
    for (let i = 0; i < rewards.length; i++) {
      if (visits === rewards[i].visits && rewards[i].visits > 0) {
        setReward({points: rewards[i].points, discount: rewards[i].discount, type: rewards[i].type});
        let obj = {
          points: 0, 
          discount: rewards[i].discount,
          type: rewards[i].type
        };
        return obj;
      }
      if (points > rewards[i].points && rewards[i].points > 0) {
        setReward({points: rewards[i].points, discount: rewards[i].discount, type: rewards[i].type});
        let obj = {
          points: rewards[i].points, 
          discount: rewards[i].discount,
          type: rewards[i].type
        };
        return obj;
      }
    }
    return null;
  };

  useEffect(() => {
    getRewards();
    const { name, phone } = checkin
    setCheckin({
      name,
      phone,
    });
    if (checkinMsg !== null) {
      if (checkinMsg.msg === "Customer checked in") {
        const obj = checkRewards(checkinMsg.visits, checkinMsg.points);
        if (obj !== null) {
          setFileName(user._id + '-' + checkinMsg.name.split(" ")[0] + '-' + checkinMsg.phone.replace(/-/g,''));
          let m = M.Modal.getInstance(promotionModalRef.current);
          m.open();
          redeemCheckin({phone: checkinMsg.phone, points: obj.points, discount: obj.discount, type: obj.type});
        } else {
          setAlert(`You are checked in, thank you! You currently have ${checkinMsg.points} reward points`, 'green', 15000);
          if (user && user.signature === true) {
            setFileName(user._id + '-' + checkinMsg.name.split(" ")[0] + '-' + checkinMsg.phone.replace(/-/g,''));
            let m = M.Modal.getInstance(signatureModalRef.current);
            m.open();
          }
        }
        clearCurrentCheckin();
        setCheckin({
          name: '',
          phone: '',
        });
      } else if (checkinMsg.msg === "Customer not found") {
        if (name.length === 0) {
          let m = M.Modal.getInstance(inputNameModalRef.current);
          m.open();
          inputNameRef.current.focus();
        } else {
          addCheckin({name, phone, inService: true, inServiceTime: Date.now()});
          setAlert('You are checked in, thank you!', 'green', 15000);
          if (user && user.signature === true) {
            setFileName(user._id + '-' + name.split(" ")[0] + '-' + phone.replace(/-/g,''));
            let m = M.Modal.getInstance(signatureModalRef.current);
            m.open();
          }
          clearCurrentCheckin();
          setCheckin({
            name: '',
            phone: '',
          });
        }
      } else if (checkinMsg.msg === "Customer already checked in") {
        setAlert('You are already checked in, thank you!', 'orange', 15000);
        setCheckin({
          name: '',
          phone: '',
        });
      }
      clearCheckinMsg();
    }
  }, [checkinContext, currentCheckin]);

  const [checkin, setCheckin] = useState({
    name: '',
    phone: '',
  });
  const { name, phone } = checkin;

  const [filename, setFileName] = useState("");

  const inputNameModalRef = useRef();
  const inputNameRef = useRef();
  const promotionModalRef = useRef();
  const signatureModalRef = useRef();
  const signatureCanvasRef = useRef({});

  const onSubmit = e => {
    e.preventDefault();
    const { phone } = checkin;
    var isError = (validatePhone(phone) === false || phone.length === 0);
    if (isError) {
      setAlert('Invalid phone number, can you please provide valid phone number?', 'red', 5000);
      return;
    }
    updateCheckin(checkin);
  };

  const onSubmitSignature = e => {
    e.preventDefault();
    if (signatureCanvasRef.current.isEmpty()) {
      var m = M.Modal.getInstance(signatureModalRef.current);
      m.close();
      m.open();
      return;
    }
    uploadSignature(signatureCanvasRef.current.getTrimmedCanvas().toDataURL("image/png"), filename);
    clearSignature();
    setFileName("");
    setAlert(`You are checked in, thank you!`, 'green', 15000);
  }

  const clearSignature = () => signatureCanvasRef.current.clear();

  const openSignature = () => {
    if (user && user.signature === true) {
      var m = M.Modal.getInstance(signatureModalRef.current);
      m.open();
    }
    setAlert(`You are checked in, thank you!`, 'green', 15000);
  }

  const onChange = e =>
  setCheckin({ ...checkin, [e.target.name]: e.target.value });

  // const clearAll = () => {
  //   clearCurrentCheckin();
  // };

  // phone input format
  let filter = [];

  const keypadZero = 48;
  const numpadZero = 96;

  for(var i = 0; i <= 9; i++){
    filter.push(i + keypadZero);
    filter.push(i + numpadZero);  
  }

  filter.push(8);//backspace
  filter.push(9);//tab
  filter.push(46);//delete
  filter.push(37);//left arrow
  filter.push(39);//right arrow

  const replaceAll = (src,search,replace) => {
    return src.split(search).join(replace);
  }

  const formatPhoneText = s => {
    let value = replaceAll(s.trim(),"-","");
    
    if(value.length > 3 && value.length <= 6) 
      value = value.slice(0,3) + "-" + value.slice(3);
    else if(value.length > 6) 
      value = value.slice(0,3) + "-" + value.slice(3,6) + "-" + value.slice(6);
    
    return value;
  }

  const validatePhone = p => {
    var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/;
    var digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
  }

  const onKeyDown = e => {  
    if(filter.indexOf(e.keyCode) < 0){
      e.preventDefault();
      return false;
    }  
  }

  const onKeyUp = e => {
    var input = e.target;
    var formatted = formatPhoneText(input.value);
    var isError = (validatePhone(formatted) || formatted.length === 0);
    var color =  (isError) ? "gray" : "red";
    var borderWidth =  (isError)? "1px" : "3px";
    input.style.borderColor = color;
    input.style.borderWidth = borderWidth;
    input.value = formatted;
  }

  const onClickPhone = e => {
    const buttons = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "-"];
    buttons.forEach( button => {
      if (e.target.name === button) {
        var formatted = formatPhoneText(phone + button);
        setCheckin({ ...checkin, 'phone':  formatted});
      }
    });
    if (e.target.name === "back") {
      if (phone.length > 0) {
        setCheckin({ ...checkin, 'phone': phone.substring(0, phone.length - 1) });
      }
    }
    if (e.target.name === "clear") {
      if (phone.length > 0) {
        setCheckin({ ...checkin, 'phone': "" });
      }
    }
  }

  const onNameKeyUp = e => {
    if (e.which === 13) {
      e.target.blur();
    }
  }

  return (
    <Fragment>
    <div id="new-customer-modal" className="modal" ref={inputNameModalRef}>
      <form onSubmit={onSubmit}>
      <div className="modal-content">
        <i className="material-icons medium black-text left">error</i>
        <p>For new customer, can you please provide your name? Thank you!</p>
        <div className="input-field">
          <input
            type="text"
            placeholder="Please click here to type your name..."
            name="name"
            value={name}
            onChange={onChange}
            onKeyUp={onNameKeyUp}
            ref={inputNameRef}
          />
        </div>
        <button 
          type="submit"
          className="modal-close waves-effect waves-light btn-flat btn-large btn-block right"><i className="material-icons right">send</i>Check In
        </button>
      </div>
      </form>
    </div>
    <div id="signature-modal" className="modal modal-fixed-footer" ref={signatureModalRef}>
      <form onSubmit={onSubmitSignature}>
      <div className="modal-content">
        <i className="material-icons medium black-text left">error</i>
        <p>COVID-19 Consent Form</p>
        <span className="black-text center">
          By signing your name in the box below, you agree to knowingly and willingly consenting to have hair/nail service during the COVID-19 pandemic.
          We reserve the right to refuse service if this form is not signed. Thank you!
        <br></br>
          *I understand the COVID-19 virus has a long incubation period during which carries of the virus may not show symptoms and still
          be highly contagious. It is impossible to determine who has it and who does not, given the current limits in virus testing. 
          I understand that due to frequency of visits of other clients, the characteristics of the virus, and the characteristics of 
          hair/nail services, that I have an elevated risk of contracting the virus simply by being in the salon.
        <br></br>
          *I confirm that I am not presenting any of the symptoms of COVID-19: temperature above 98.7 degrees, shortness of breath, loss
          of sense of taste or smell, dry cough, sore throat. I confirm that I do not live with anyone who is sick or quarantined.
        <br></br>
          *To present the spread of contagious viruses and to help protect each other, I understand that I will follow the salon's guidelines.
        <br></br>
          *I verify that I have not travel outside the United States in the past 14 days to countries that have been affected by COVID-19. 
          I verify that I have not traveled domestically within the United States by commercial airline, bus, or train within the past 14 days.
        </span>
        <div className="center row">
          <SignatureCanvas ref={signatureCanvasRef} penColor='black' canvasProps={{width: 600, height: 150, className: 'signatureCanvas'}} />
        </div>
      </div>
      <div className="modal-footer">
        <button 
          type="submit"
          className="modal-close waves-effect waves-light btn-flat btn-large btn-block right"><i className="material-icons right">send</i>Agree
        </button>
        <a
          href="#"
          className="waves-effect waves-light btn-flat btn-large btn-block right" onClick={clearSignature}><i className="material-icons right">clear</i>Clear
        </a>
        </div>
      </form>
    </div>
    <div id="promotion-modal" className="modal" ref={promotionModalRef}>
      <div className="modal-content">
        <i className="material-icons medium black-text left">error</i>
        { reward && reward.type === '$' ? (
        <p className="black-text center">
          Congratulations! Today is your day. We'd love to offer you ${reward && reward.discount} off today.
          A text message is sent to you, please show it as checking out to get discount.
        </p>
        ) : (
        <p className="black-text center">
          Congratulations! Today is your day. We'd love to offer you {reward && reward.discount}% off today.
          A text message is sent to you, please show it as checking out to get discount.
        </p>  
        )
        }
        <img
          src={giftopen}
          style={{ width: '200px', margin: 'auto', display: 'block' }}
          alt='Loading...'
        />
        <a href="#"
          className="modal-close waves-effect waves-light btn-flat btn-large btn-block right" onClick={openSignature}><i className="material-icons right">redeem</i>Close
        </a>
      </div>
    </div>
    <div className="row">
      <div className="col s12">
        <div className="card card-login">
          <div className="card-login-splash">
            <div className="wrapper">
              <h3 className="center card-title">Welcome to {user && user.name}</h3>
              <br></br>
              <h6 className="center">Please check in with your phone number</h6>
              <div className="row center">
              <span>By providing your phone number, you agree to our <a href="#terms-policies-modal" className="modal-trigger">Terms and Policies</a> and that our salon may send you text messages about feedbacks, promotions, and reminders!</span>
              </div>
            </div>
            <img src="/images/geometric.jpg" alt="" width="100%" height="100%"></img>
          </div>
          <div className="card-content">
            <span className="card-title">Check In</span>
            <form onSubmit={onSubmit}>
            <div className="input-field">
              <span htmlFor='phone' className="black-text">Phone Number</span>
              <input
                type="tel"
                name="phone"
                placeholder=""
                value={phone}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                readOnly
              />
            </div>
            <div className="row">
              <div className="col s4">
                <button type="button" name="1" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>1</button>
              </div>
              <div className="col s4">
                <button type="button" name="2" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>2</button>
              </div>
              <div className="col s4">
                <button type="button" name="3" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>3</button>
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <button type="button" name="4" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>4</button>
              </div>
              <div className="col s4">
                <button type="button" name="5" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>5</button>
              </div>
              <div className="col s4">
                <button type="button" name="6" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>6</button>
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <button type="button" name="7" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>7</button>
              </div>
              <div className="col s4">
                <button type="button" name="8" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>8</button>
              </div>
              <div className="col s4">
                <button type="button" name="9" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>9</button>
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <button type="button" name="clear" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>Clear</button>
              </div>
              <div className="col s4">
                <button type="button" name="0" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>0</button>
              </div>
              <div className="col s4">
                <button type="button" name="back" className="waves-effect waves-green btn-large btn-floating white black-text" onClick={onClickPhone}>back</button>
              </div>
            </div>
            <div className="input-field">
              <button
                type="submit"
                className="waves-effect waves-light btn-flat btn-large btn-block right"><i className="material-icons right">send</i>Check In
              </button>
            </div>
            </form>
          </div>
        </div>    
      </div>    
    </div>
    </Fragment>
  );
};

export default CheckinForm;
