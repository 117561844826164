import {
  GET_APPOINTMENTS,
  ADD_APPOINTMENT,
  UPDATE_APPOINTMENT,
  GET_CURRENT_APPOINTMENT,
  SET_CURRENT_APPOINTMENT,
  CLEAR_CURRENT_APPOINTMENT,
  APPOINTMENT_ERROR
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
        loading: false
      };
    case ADD_APPOINTMENT:
      return {
        ...state,
        appointments: [action.payload, ...state.appointments],
        loading: false
      };
    case UPDATE_APPOINTMENT:
      return {
        ...state,
        currentAppointment: action.payload
      };
    case GET_CURRENT_APPOINTMENT:
      return {
        ...state,
        currentAppointment: action.payload
      };
    case SET_CURRENT_APPOINTMENT:
      return {
        ...state,
        currentAppointment: action.payload
      };
    case CLEAR_CURRENT_APPOINTMENT:
      return {
        ...state,
        currentAppointment: null
      };
    case APPOINTMENT_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
