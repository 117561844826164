import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import PageContext from '../../context/page/pageContext';
import CustomerContext from '../../context/customer/customerContext';
import ReviewContext from '../../context/review/reviewContext';
import AppointmentContext from '../../context/appointment/appointmentContext';

import CanvasJSReact, {CanvasJS, CanvasJSChart} from '../../utils/canvasjs.react';

const DashboardPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);
  const customerContext = useContext(CustomerContext);
  const reviewContext = useContext(ReviewContext);
  const appointmentContext = useContext(AppointmentContext);

  const { user } = authContext;
  const { customers, getCustomers } = customerContext;
  const { reviews, getReviews } = reviewContext;
  const { appointments, getAppointments } = appointmentContext;

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Dashboard");
    getCustomers();
    getReviews();
    getAppointments();
    // eslint-disable-next-line
  }, []);

  const [tab, setTab] = useState("customers");

  let customerOptions = {
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Customers Overview"
    },
  }

  let numbersOfInOut = 0;
  let numbersOfInOutToday = 0;
  let numbersOfInOutYesterday = 0;
  let numbersOfInOutTwoDaysAgo = 0;
  let numbersOfInOutThreeDaysAgo = 0;
  let numberOfCustomers = 0;
  let overviewCustomers = "";

  if (customers !== null && customers.length !== 0 && !customerContext.loading) {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0, 0);
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    let twoDaysAgo = new Date(today);
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
    let threeDaysAgo = new Date(today);
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    customers.forEach( c => {
      numbersOfInOut += c.visits;
      let inServiceTime = new Date(c.inServiceTime);
      inServiceTime.setHours(0);
      inServiceTime.setMinutes(0);
      inServiceTime.setSeconds(0, 0);
      if (inServiceTime.getTime() === today.getTime()) {
        numbersOfInOutToday += 1;
      }
      if (inServiceTime.getTime() === yesterday.getTime()) {
        numbersOfInOutYesterday += 1;
      }
      if (inServiceTime.getTime() === twoDaysAgo.getTime()) {
        numbersOfInOutTwoDaysAgo += 1;
      }
      if (inServiceTime.getTime() === threeDaysAgo.getTime()) {
        numbersOfInOutThreeDaysAgo += 1;
      }
    });
    let newCustomers     = customers.filter(c => c.visits < 3).length/customers.length*100;
    let regularCustomers = customers.filter(c => c.visits >= 3 && c.visits <= 9).length/customers.length*100;
    let vipCustomers     = customers.filter(c => c.visits > 9).length/customers.length*100;

    numberOfCustomers = customers.length;

    const maxCustomers = Math.max(newCustomers, regularCustomers, vipCustomers);
    if (newCustomers === maxCustomers) {
      overviewCustomers = "Most of the customers are new";
    } else if (regularCustomers === maxCustomers) {
      overviewCustomers = "Most of the customers are regular";
    } else if (vipCustomers === maxCustomers) {
      overviewCustomers = "Most of the customers are VIP";
    }

    customerOptions = {
      animationEnabled: true,
      theme: "light2",
      title: {
        text: "Customers Overview"
      },
      data: [{
        type: "doughnut",
        showInLegend: true,
        indexLabel: "{name}: {y}",
        yValueFormatString: "#,###'%'",
        dataPoints: [
          { name: "New", y: newCustomers, color: "#00b0ff" },
          { name: "Regular", y: regularCustomers, color: "#70be74" },
          { name: "VIP", y: vipCustomers, color: "#ff4081" },
        ]
      }]
    }
  }

  let numberOfReviews = "";
  let overviewReviews = "";

  let reviewOptions = {
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Reviews Overview"
    },
  }

  if (reviews !== null && reviews.length !== 0 && !reviewContext.loading) {
    let veryUnsatisfied = reviews.filter(r => r.rating === 1).length/reviews.length*100;
    let unsatisfied     = reviews.filter(r => r.rating === 2).length/reviews.length*100;
    let neural          = reviews.filter(r => r.rating === 3).length/reviews.length*100;
    let satisfied       = reviews.filter(r => r.rating === 4).length/reviews.length*100;
    let verySatisfied   = reviews.filter(r => r.rating === 5).length/reviews.length*100;

    numberOfReviews = reviews.length;

    const maxReviews = Math.max(veryUnsatisfied, unsatisfied, neural, satisfied, verySatisfied);
    if (veryUnsatisfied === maxReviews) {
      overviewReviews = "Most of the reviews are very unsatisfied";
    } else if (unsatisfied === maxReviews) {
      overviewReviews = "Most of the reviews are unsatisfied";
    } else if (neural === maxReviews) {
      overviewReviews = "Most of the reviews are neural";
    } else if (satisfied === maxReviews) {
      overviewReviews = "Most of the reviews are satisfied";
    } else if (verySatisfied === maxReviews) {
      overviewReviews = "Most of the reviews are very satisfied";
    }

    reviewOptions = {
      animationEnabled: true,
      theme: "light2",
      title: {
        text: "Reviews Overview"
      },
      data: [{
        type: "doughnut",
        showInLegend: true,
        indexLabel: "{name}: {y}",
        yValueFormatString: "#,###'%'",
        dataPoints: [
          { name: "Very Unsatisfied", y: veryUnsatisfied, color: "#ff4081" },
          { name: "Unsatisfied", y: unsatisfied, color: "#ff80ab" },
          { name: "Neutral", y: neural, color: "#ffc400" },
          { name: "Satisfied", y: satisfied, color: "#4db6ac" },
          { name: "Very Satisfied", y: verySatisfied, color: "#009688" }
        ]
      }]
    }
  }

  let numberOfAppointments = 0;
  let overviewAppointments = "There are no appointments on calendar";

  if (appointments !== null && appointments.length !== 0 && !appointmentContext.loading) {
    numberOfAppointments = appointments.length;
    overviewAppointments = `There are ${numberOfAppointments} appointments on calendar`;
  }

  let overviewReward = "";
  if (user) {
    overviewReward = `${user.autoCheckOut} reward points are given for each visit`;
  }

  return (
    <div className="row equal-height-grid">

    <div className="col l9 m9 s12">

      <div className="card">
        <div className="card-metrics card-metrics-toggle card-metrics-centered">
          <div className={"card-metric waves-effect" + (tab === "customers" ? " active" : "")} data-metric="customers" id="customers" onClick={() => setTab("customers")}>
            <div className="card-metric-title">Customers</div>
            <div className="card-metric-value">{numberOfCustomers}</div>
          </div>
          <div className={"card-metric waves-effect" + (tab === "reviews" ? " active" : "")} data-metric="reviews" id="reviews" onClick={() => setTab("reviews")}>
            <div className="card-metric-title">Reviews</div>
            <div className="card-metric-value">{numberOfReviews}</div>
          </div>
        </div>
        <div className="card-content">
            {(tab === "customers" ? 
            <CanvasJSChart options = {customerOptions} /> : 
            <CanvasJSChart options = {reviewOptions} />
            )}
        </div>
      </div>

    </div>

    <div className="col l3 m3 s12">
      <div className="card">
        <div className="card-metrics card-metrics-toggle card-metrics-centered">
          <div className="card-metric active">
            <div className="card-metric-title">In/Out</div>
              <div className="card-metric-value">{numbersOfInOut}</div>
          </div>
        </div>
        <div className="card-content">
        <table>
          <tbody>
            <tr>
              <td><i className="material-icons tiny light-blue-text text-accent-3">brightness_1</i></td>
              <td>Today</td>
              <td>{numbersOfInOutToday}</td>
            </tr>
            <tr>
              <td><i className="material-icons tiny pink-text text-accent-2">brightness_1</i></td>
              <td>Yesterday</td>
              <td>{numbersOfInOutYesterday}</td>
            </tr>
            <tr>
              <td><i className="material-icons tiny yellow-text text-darken-3">brightness_1</i></td>
              <td>Two days ago</td>
              <td>{numbersOfInOutTwoDaysAgo}</td>
            </tr>
            <tr>
              <td><i className="material-icons tiny green-text text-lighten-1">brightness_1</i></td>
              <td>Three days ago</td>
              <td>{numbersOfInOutThreeDaysAgo}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>

    <div className="col l3 m6 s12">
      <div className="card admin-blue white-text">
        <div className="card-toolbar">
          <div className="card-toolbar-title"><i className="material-icons left">people</i>Customers</div>
          <div className="card-toolbar-actions">
            <div className="dropdown-trigger" data-target="admin-blue-dropdown">
              <i className="material-icons">more_vert</i>
            </div>
          </div>
          <ul id="admin-blue-dropdown" className="dropdown-content">
            <li><a href="/customers">Learn More</a></li>
          </ul>
        </div>
        <div className="card-content card-align-bottom">
          <span className="card-title">{overviewCustomers}</span>
          <p className="white-text"></p>
        </div>
      </div>
    </div>

    <div className="col l3 m6 s12">
      <div className="card admin-pink white-text">
        <div className="card-toolbar">
          <div className="card-toolbar-title"><i className="material-icons left">star</i>Reviews</div>
          <div className="card-toolbar-actions">
            <div className="dropdown-trigger" data-target="admin-pink-dropdown">
              <i className="material-icons">more_vert</i>
            </div>
          </div>
          <ul id="admin-pink-dropdown" className="dropdown-content">
            <li><a href="/reviews">Learn More</a></li>
          </ul>
        </div>
        <div className="card-content card-align-bottom">
          <span className="card-title">{overviewReviews}</span>
          <p className="white-text"></p>
        </div>
      </div>
    </div>

    <div className="col l3 m6 s12">
      <div className="card admin-yellow white-text">
        <div className="card-toolbar">
          <div className="card-toolbar-title"><i className="material-icons left">event_note</i>Calendar</div>
          <div className="card-toolbar-actions">
            <div className="dropdown-trigger" data-target="admin-yellow-dropdown">
              <i className="material-icons">more_vert</i>
            </div>
          </div>
          <ul id="admin-yellow-dropdown" className="dropdown-content">
            <li><a href="/calendar">Learn More</a></li>
          </ul>
        </div>
        <div className="card-content card-align-bottom">
          <span className="card-title">{overviewAppointments}</span>
          <p className="white-text"></p>
        </div>
      </div>
    </div>

    <div className="col l3 m6 s12">
      <div className="card admin-green white-text">
        <div className="card-toolbar">
          <div className="card-toolbar-title"><i className="material-icons left">card_giftcard</i>Reward</div>
          <div className="card-toolbar-actions">
            <div className="dropdown-trigger" data-target="admin-green-dropdown">
              <i className="material-icons">more_vert</i>
            </div>
          </div>
          <ul id="admin-green-dropdown" className="dropdown-content">
            <li><a href="/rewards">Learn More</a></li>
          </ul>
        </div>
        <div className="card-content card-align-bottom">
          <span className="card-title">{overviewReward}</span>
          <p className="white-text"></p>
        </div>
      </div>
    </div>

    </div>

  );
};

export default DashboardPage;
