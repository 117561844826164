import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ServiceContext from '../../context/service/serviceContext';

const ServiceItem = ({ service }) => {
  const serviceContext = useContext(ServiceContext);
  
  const { deleteService, clearCurrentService } = serviceContext;

  const { _id, name, duration, price } = service;

  const onDelete = () => {
    deleteService(_id);
    clearCurrentService();
  };

  return (
    <tr role="row">
      <td>{name}</td>
      <td>{duration} minutes</td>
      <td>${price}</td>
      <td>
        <a href="#" className="waves-effect waves-light" onClick={onDelete}>
          <i className="material-icons red-text">delete</i>
        </a>
      </td>
    </tr>
  );
};

ServiceItem.propTypes = {
  service: PropTypes.object.isRequired
};

export default ServiceItem;
