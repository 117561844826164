import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';
import PageContext from '../../context/page/pageContext';
import AuthContext from '../../context/auth/authContext';
import CustomerContext from '../../context/customer/customerContext';

const Navbar = () => {
  const pageContext = useContext(PageContext);
  const authContext = useContext(AuthContext);
  const customerContext = useContext(CustomerContext);

  const { page } = pageContext;
  const { isAuthenticated, logout, user } = authContext;
  const { clearCustomers } = customerContext;

  const onLogout = () => {
    logout();
    clearCustomers();
  };

  if (page === "Feedbacks" || page === "Appointments") {
    return (
      <Fragment>
      </Fragment>
    )
  }

  const authLinks = (
    <Fragment>
      <li>
        <div className="user-view">
          <div className="background">
            <img src="/images/geometric.jpg"></img>
          </div>
          <i className="material-icons medium white-text">swap_horiz</i>
          <span className="white-text name">Welcome to {user && user.name}</span>
          <span className="white-text text">"Always Make Customers Happy"</span>
        </div>
      </li>
      <li className="no-padding">
        <ul className="collapsible collapsible-accordion">
          <li className="bold waves-effect white active"><a className="collapsible-header">Menu<i className="material-icons chevron">chevron_left</i></a>
            <div className="collapsible-body">
              <ul>
                <li>
                  <a href='/' className={"waves-effect" + (page === "Dashboard" ? " active" : "")}>
                    <i className="material-icons black-text">dashboard</i>Dashboard
                  </a>
                </li>
                <li>
                  <a href='/checkins' className={"waves-effect" + (page === "Check In" ? " active" : "")}>
                    <i className="material-icons black-text">arrow_forward</i>Check In
                  </a>
                </li>
                <li>
                  <a href='/checkouts' className={"waves-effect" + (page === "Check Out" ? " active" : "")}>
                    <i className="material-icons black-text">arrow_back</i>Check Out
                  </a>
                </li>
                <li>
                  <a href='/calendar' className={"waves-effect" + (page === "Calendar" ? " active" : "")}>
                    <i className="material-icons black-text">event_note</i>Calendar
                  </a>
                </li>
                <li>
                  <a href='/reviews' className={"waves-effect" + (page === "Reviews" ? " active" : "")}>
                    <i className="material-icons black-text">star_border</i>Reviews
                  </a>
                </li>
                <li>
                  <a href='/customers' className={"waves-effect" + (page === "Customers" ? " active" : "")}>
                    <i className="material-icons black-text">people_outline</i>Customers
                  </a>
                </li>
                <li><div className="divider"></div></li>
                <li>
                  <a href='/settings' className={"waves-effect" + (page === "Settings" ? " active" : "")}>
                    <i className="material-icons black-text">settings_applications</i>Settings
                  </a>
                </li>
                <li>
                  <a href='/' className="waves-effect" onClick={onLogout}>
                    <i className="material-icons black-text">lock_open</i>Log Out
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="bold waves-effect white active"><a className="collapsible-header">More<i className="material-icons chevron">chevron_left</i></a>
            <div className="collapsible-body">
              <ul>
                <li>
                  <a href='/promotions' className={"waves-effect" + (page === "Promotions" ? " active" : "")}>
                    <i className="material-icons black-text">card_membership</i>Promotions
                  </a>
                </li>
                <li>
                  <a href='/reminders' className={"waves-effect" + (page === "Reminders" ? " active" : "")}>
                    <i className="material-icons black-text">access_alarms</i>Reminders
                  </a>
                </li>
                <li>
                  <a href='/rewards' className={"waves-effect" + (page === "Rewards" ? " active" : "")}>
                    <i className="material-icons black-text">card_giftcard</i>Rewards
                  </a>
                </li>
                <li><div className="divider"></div></li>
                <li>
                  <a href='/staffs' className={"waves-effect" + (page === "Staff" ? " active" : "")}>
                    <i className="material-icons black-text">card_travel</i>Staff
                  </a>
                </li>
                <li>
                  <a href='/services' className={"waves-effect" + (page === "Services" ? " active" : "")}>
                    <i className="material-icons black-text">content_paste</i>Services
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <li>
        <div className="user-view">
          <div className="background">
            <img src="/images/geometric.jpg" alt=""></img>
          </div>
          <i className="material-icons medium white-text">swap_horiz</i>
          <span className="white-text name">Please Register or Log In</span>
          <span className="white-text text">"Always Make Customers Happy"</span>
        </div>
      </li>
      <li className="no-padding">
        <ul className="collapsible collapsible-accordion">
          <li className="bold waves-effect white active"><a className="collapsible-header">Menu<i className="material-icons chevron">chevron_left</i></a>
            <div className="collapsible-body">
              <ul>
                <li>
                  <a href="/register" className={"waves-effect" + (page === "Register" ? " active" : "")}>
                    <i className="material-icons black-text">person_add</i>Register
                  </a>
                </li>
                <li>
                  <a href="/login" className={"waves-effect" + (page === "Log In" ? " active" : "")}>
                    <i className="material-icons black-text">lock</i>Log In
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </li>
    </Fragment>
  );

  return (
    <header>
        <nav className="navbar white navbar nav-extended no-padding">
          <div className='nav-wrapper'>
            <a className="brand-logo grey-text text-darken-4">{page}</a>
            <ul id="nav-mobile" className="right">
              <li>
                <a href="#" data-target="notification-dropdown" className="dropdown-trigger waves-effect">
                  {isAuthenticated && (<i className="material-icons black-text">notifications</i>)}
                </a>
              </li>
            </ul>
            <a href="#" data-target="sidenav-left" className="sidenav-trigger left"><i className="material-icons black-text">menu</i></a>
          </div>
          {!isAuthenticated &&
          (<Fragment>
          <div class="nav-content">
            <div class="nav-metrics row">
              <div class="col s4 no-padding">
                <div class="card horizontal">
                  <div class="card-stacked">
                    <div class="card-metrics card-metrics-static">
                      <div class="card-metric active">
                        <div class="card-metric-title">Salons</div>
                        <div class="card-metric-value">112</div>
                        <div class="card-metric-change increase">25%<i class="material-icons left">keyboard_arrow_up</i></div>
                      </div>
                    </div>
                  </div>
                  <div class="card-content">
                    <canvas id="mini-line-chart" width="100%" height="100%"></canvas>
                  </div>
                </div>
              </div>
              <div class="col s4 no-padding">
                <div class="card horizontal">
                  <div class="card-stacked">
                    <div class="card-metrics card-metrics-static">
                      <div class="card-metric active">
                        <div class="card-metric-title">Customers</div>
                        <div class="card-metric-value">295680</div>
                        <div class="card-metric-change increase">12%<i class="material-icons left">keyboard_arrow_up</i></div>
                      </div>
                    </div>
                  </div>
                  <div class="card-content">
                    <canvas id="mini-stacked-bar-chart" width="100%" height="100%"></canvas>
                  </div>
                </div>
              </div>
              <div class="col s4 no-padding">
                <div class="card horizontal">
                  <div class="card-stacked">
                    <div class="card-metrics card-metrics-static">
                      <div class="card-metric active">
                        <div class="card-metric-title">Reviews</div>
                        <div class="card-metric-value">591200</div>
                        <div class="card-metric-change increase">16%<i class="material-icons left">keyboard_arrow_up</i></div>
                      </div>
                    </div>
                  </div>
                  <div class="card-content">
                    <canvas id="mini-doughnut-chart" width="100%" height="100%"></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Fragment>)}  
        </nav>
      <ul id="sidenav-left" className="sidenav white">
        {isAuthenticated ? authLinks : guestLinks}
      </ul>
      <div id='notification-dropdown' className='dropdown-content notifications'>
        {isAuthenticated &&
        (<Fragment>
        <div className="notifications-title">notifications</div>
        <div className="card">
          <div className="card-content"><span className="card-title"></span>
            <p>Thank you for your business, we try our best to make customers happy!</p>
          </div>
        </div>
        </Fragment>)}
      </div>
    </header>
  );
};

Navbar.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string
};

export default Navbar;
