import React, { Fragment, useContext, useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ReviewItem from './ReviewItem';
import Spinner from '../layout/Spinner';
import ReviewContext from '../../context/review/reviewContext';
import StarRating from 'react-star-rating-component';

const Reviews = () => {
  const reviewContext = useContext(ReviewContext);

  const { reviews, getReviews, loading } = reviewContext;

  const [pageNumber, setPageNumber] = useState(0);

  const NUMBER_OF_REVIEWS_PER_PAGE = 10;

  useEffect(() => {
    getReviews();
    // eslint-disable-next-line
  }, []);

  const onNext = () => {
    if (pageNumber >= (reviews.length/NUMBER_OF_REVIEWS_PER_PAGE - 1)) {
      setPageNumber(pageNumber);
    } else {
      setPageNumber(pageNumber+1);
    }
  };

  const onPrev = () => {
    if (pageNumber === 0) {
      setPageNumber(0);
    } else {
      setPageNumber(pageNumber-1);
    }
  };

  if (reviews !== null && reviews.length === 0 && !loading) {
    return (
    <Fragment>
      <table className="highlight striped centered responsive-table" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th>Rating</th>
            <th>Comment</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </Fragment>
    );
  }

  return (
    <Fragment>
      {reviews !== null && !loading ? (
        <TransitionGroup>
          <table className="highlight centered responsive-table" cellSpacing="0" width="100%">
          <thead>
            <tr>
              <th>
                <StarRating name={"null"} value="1" starCount="1" starColor={"#ffffffff"} editing={false} renderStarIcon={() => <i className="material-icons">star</i>}/>
                Rating
                <StarRating name={"null"} value="1" starCount="1" starColor={"#ffffffff"} editing={false} renderStarIcon={() => <i className="material-icons">star</i>}/>
              </th>
              <th>Comment</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reviews.slice(pageNumber*NUMBER_OF_REVIEWS_PER_PAGE, (pageNumber+1)*NUMBER_OF_REVIEWS_PER_PAGE).map(review => (
              <CSSTransition
                key={review._id}
                timeout={500}
              >
                <ReviewItem review={review} />
              </CSSTransition>
            ))}
          </tbody>
          </table>
        </TransitionGroup>
      ) : (
        <Spinner />
      )}
      <div className="row">
        <ul className="pagination right">
          <li className="waves-effect"><a href="#" onClick={onPrev}><i className="material-icons">chevron_left</i></a></li>
          <li className="disabled"><a href="#">Prev</a></li>
          <li className="disabled"><a href="#">Next</a></li>
          <li className="waves-effect"><a href="#" onClick={onNext}><i className="material-icons">chevron_right</i></a></li>
        </ul>
      </div>
    </Fragment>
  );
};

export default Reviews;
