import {
  SELECT_PAGE,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case SELECT_PAGE:
      return {
        page: action.payload
      };
    default:
      return state;
  }
};
