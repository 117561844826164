import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CustomerContext from '../../context/customer/customerContext';
import Avatar from 'react-avatar';
import moment from "moment";

const CustomerItem = ({ customer }) => {
  const customerContext = useContext(CustomerContext);
  // const { deleteCustomer, setCurrentCustomer, clearCurrentCustomer } = customerContext;

  const { setCurrentCustomer } = customerContext;

  const { name, phone, visits, inServiceTime } = customer;

  // const onDelete = () => {
  //   deleteCustomer(_id);
  //   clearCurrentCustomer();
  // };

  return (
    <tr role="row">
      <td><Avatar name={name} size="50px" color="#b2dfdb" round /></td>
      <td>{name}</td>
      <td>{phone}</td>
      <td>{visits}</td>
      <td>{moment(inServiceTime).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td><span className={'white-text badge ' + (visits >= 3 ? (visits >= 9 ? 'pink lighten-2' : 'teal lighten-2') : 'blue lighten-2')}>{(visits >= 3 ? (visits >= 9 ? 'VIP' : 'Regular') : 'New')}</span></td>
      <td>
        <a href="#add-customer-modal" className="waves-effect waves-light modal-trigger" onClick={() => setCurrentCustomer(customer)}>
          <i className="material-icons teal-text">edit</i>
        </a>
      </td>
    </tr>
  );
};

CustomerItem.propTypes = {
  customer: PropTypes.object.isRequired
};

export default CustomerItem;
