import React, { useContext } from 'react';
import ReminderContext from '../../context/reminder/reminderContext';

const AddReminderBtn = () => {
  const reminderContext = useContext(ReminderContext);

  const { clearCurrentReminder } = reminderContext;

  const clearAll = () => {
    clearCurrentReminder();
  };

  return (
    <div className="fixed-action-btn">
      <a href="#add-reminder-modal" className="btn-floating btn-large teal modal-trigger" onClick={clearAll}>
        <i className="large material-icons">add</i>
      </a>
    </div>
  )
}

export default AddReminderBtn;