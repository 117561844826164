import React, { useContext } from 'react';
import AlertContext from '../../context/alert/alertContext';
// import Modal from 'react-modal';
import PageContext from '../../context/page/pageContext';
// const customStyles = {
//   overlay: {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(0, 0, 0, 1)'
//   },
//   content : {
//     top                   : '25%',
//     left                  : '50%',
//     right                 : 'auto',
//     bottom                : 'auto',
//     transform             : 'translate(-50%, -50%)',
//   }
// };

const Alerts = () => {
  const pageContext = useContext(PageContext);
  const alertContext = useContext(AlertContext);

  return (
    pageContext.page !== "Check In" ? (
    alertContext.alerts.length > 0 &&
    alertContext.alerts.map(alert => (
      <div className="row">
        <div className="col l8 offset-l2 m10 offset-m1 s12">
          <div className={`card-panel ${alert.type} center-align`}>
          <i className="material-icons white-text left">error</i><span className="white-text center"> {alert.msg}</span>
          </div>
        </div>
      </div>
    ))) : (
      alertContext.alerts.length > 0 &&
      alertContext.alerts.map(alert => (
      <div className="row">
        <div className="col s12">
          <div className={`card-panel ${alert.type} center-align`}>
          <i className="material-icons medium white-text left">error</i><p className="white-text center"> {alert.msg}</p>
          </div>
        </div>
      </div>
    ))));

  // return (
  //   alertContext.alerts.length > 0 &&
  //   alertContext.alerts.map(alert => (
  //     <Modal
  //         isOpen={true}
  //         style={customStyles}
  //         contentLabel="Example Modal"
  //       >
  //         <div className="row">
  //           <div className={`card-panel ${alert.type} center-align`}>
  //             <i className="material-icons white-text">warning</i><span className="white-text"> {alert.msg}</span>
  //           </div>
  //        </div>
  //       </Modal>
  //   ))
  // );
};

export default Alerts;
