import React, { useState, useContext, useEffect, useRef } from 'react';
import PromotionContext from '../../context/promotion/promotionContext';
import M from 'materialize-css/dist/js/materialize.min.js';

const PromotionTextForm = () => {
  const promotionContext = useContext(PromotionContext);
  const { updatePromotion, clearCurrentPromotion, currentPromotion } = promotionContext;
  const inputTextRef = useRef();

  useEffect(() => {
    M.textareaAutoResize(inputTextRef.current);
    if (currentPromotion !== null) {
      setPromotion(currentPromotion);
    }
  }, [promotionContext, currentPromotion]);

  const [promotion, setPromotion] = useState({
    event: '',
    discount: 0,
    type: '$',
    visits: 0,
    from: null,
    to: null,
    text: ''
  });

  const { text } = promotion;

  const onChange = e =>
    setPromotion({ ...promotion, [e.target.name]: e.target.value });

  const onSelect = e => {
    M.textareaAutoResize(e.target);
  }

  const onSubmit = e => {
    e.preventDefault();
    updatePromotion(promotion);
    clearAll();
  };

  const clearAll = () => {
    clearCurrentPromotion();
  };

  return (
    <div id="promotion-text-modal" className="modal">
      <form onSubmit={onSubmit}>
      <div className="modal-content">
        <h6 className="center-align">
          Edit SMS Text Message
        </h6>
        <div class="row">
          <div className="input-field col s12">
            <label htmlFor="text">SMS Text Message</label>
            <textarea
              className="materialize-textarea"
              placeholder=""
              name="text"
              value={text}
              onChange={onChange}
              onSelect={onSelect}
              maxLength="140"
              ref={inputTextRef}
            />
          </div>
        </div>
      </div>  
      <div className="modal-footer">
        <button
          type="submit"
          className="modal-close btn-large btn-flat"><i className="material-icons right">edit</i>Edit SMS Text
        </button>
      </div>
      </form>
    </div>
  );
};

export default PromotionTextForm;
