import React, { useState, useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import PageContext from '../../context/page/pageContext';

const LoginPage = props => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  const { setAlert } = alertContext;
  const { login, error, clearErrors, isAuthenticated } = authContext;

  useEffect(() => {
    pageContext.selectPage("Log In")

    if (isAuthenticated) {
      props.history.push('/');
    }

    if (error === 'Invalid Email' || error === 'Invalid Password') {
      setAlert(error, 'red');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [user, setUser] = useState({
    email: '',
    password: ''
  });

  const { email, password } = user;

  const onChange = e => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (email === '' || password === '') {
      setAlert('Please fill in all fields', 'red');
    } else {
      login({
        email,
        password
      });
    }
  };

  return (
    <Fragment>
    <div className="row">
      <div className="col l8 offset-l2 m10 offset-m1 s12">
        <div className="card card-login">
          <div className="card-login-splash">
            <div className="wrapper">
              <h4>Who is in/out?</h4>
            </div>
            <img src="/images/geometric.jpg" alt="" width="100%" height="100%"></img>
          </div>
          <div className="card-content">
            <span className="card-title">Log In</span>
            <form onSubmit={onSubmit}>
              <div className='input-field'>
                <input
                  type='email'
                  className='validate'
                  name='email'
                  placeholder=""
                  value={email}
                  onChange={onChange}
                  required
                />
                <label htmlFor='email'>Email</label>
              </div>
              <div className='input-field'>
                <input
                  type='password'
                  className='validate'
                  name='password'
                  placeholder=""
                  value={password}
                  onChange={onChange}
                  required
                />
                <label htmlFor='password'>Password</label>
              </div>
              <input
                type='submit'
                value='Log In'
                className='btn btn-primary btn-block right'
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className="row center">
      <div className="col l8 offset-l2 m10 offset-m1 s12">
        <div className="card">
          <div className="card-content">
            <p>"Our mission is to help businesses delight the customer by building 
            a long lasting relationship. Our digital platform allows the customer
            to review, connect, and have a present voice in their community's 
            favorite local business."</p>
        </div>
      </div>
    </div>
    </div>
    </Fragment>
  );
};

export default LoginPage;
