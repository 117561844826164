import {
  GET_CUSTOMERS,
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  SET_CURRENT_CUSTOMER,
  CLEAR_CURRENT_CUSTOMER,
  UPDATE_CUSTOMER,
  FILTER_CUSTOMERS,
  CLEAR_FILTER_CUSTOMERS,
  CUSTOMER_ERROR,
  CLEAR_CUSTOMERS
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
        loading: false
      };
    case ADD_CUSTOMER:
      return {
        ...state,
        customers: [action.payload, ...state.customers],
        loading: false
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.map(customer =>
          customer._id === action.payload._id ? action.payload : customer
        ),
        loading: false
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter(
          customer => customer._id !== action.payload
        ),
        loading: false
      };
    case CLEAR_CUSTOMERS:
      return {
        ...state,
        customers: null,
        filteredCustomers: null,
        error: null,
        currentCustomer: null
      };
    case SET_CURRENT_CUSTOMER:
      return {
        ...state,
        currentCustomer: action.payload
      };
    case CLEAR_CURRENT_CUSTOMER:
      return {
        ...state,
        currentCustomer: null
      };
    case FILTER_CUSTOMERS:
      return {
        ...state,
        filteredCustomers: state.customers.filter(customer => {
          // optional for visits
          if (action.payload.includes(">=")) {
            return (customer.visits >= action.payload.substring(2));
          } else if (action.payload.includes("<=")) {
            return (customer.visits <= action.payload.substring(2));
          } else if (action.payload.includes(">")) {
            return (customer.visits > action.payload.substring(1));
          } else if (action.payload.includes("<")) {
            return (customer.visits < action.payload.substring(1));
          } else if (action.payload.includes("=")) {
            return (customer.visits == action.payload.substring(1));
          }
          const regex = new RegExp(`${action.payload}`, 'gi');
          return customer.name.match(regex) || customer.phone.replace(/-/g,"").match(regex);
        })
      };
    case CLEAR_FILTER_CUSTOMERS:
      return {
        ...state,
        filteredCustomers: null
      };
    case CUSTOMER_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
