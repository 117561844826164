import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import AddStaffBtn from '../staffs/AddStaffBtn';
import Staffs from '../staffs/Staffs';
import StaffForm from '../staffs/StaffForm';
import StaffDateForm from '../staffs/StaffDateForm';
import PageContext from '../../context/page/pageContext';

const CustomersPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Staff")
    // eslint-disable-next-line
  }, []);

  
  return (
    <div className="row">
      <AddStaffBtn />
      <StaffForm />
      <StaffDateForm />
      <div className="card">
        <div className="row">
          <Staffs />
        </div>
      </div>
    </div>
  );
};

export default CustomersPage;
