import {
  GET_STAFFS,
  ADD_STAFF,
  DELETE_STAFF,
  SET_CURRENT_STAFF,
  SET_CURRENT_SHIFT,
  CLEAR_CURRENT_STAFF,
  UPDATE_STAFF,
  STAFF_ERROR,
  CLEAR_STAFFS
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_STAFFS:
      return {
        ...state,
        staffs: action.payload,
        loading: false
      };
    case ADD_STAFF:
      return {
        ...state,
        staffs: [action.payload, ...state.staffs],
        loading: false
      };
    case UPDATE_STAFF:
      return {
        ...state,
        staffs: state.staffs.map(staff =>
          staff._id === action.payload._id ? action.payload : staff
        ),
        loading: false
      };
    case DELETE_STAFF:
      return {
        ...state,
        staffs: state.staffs.filter(
          staff => staff._id !== action.payload
        ),
        loading: false
      };
    case CLEAR_STAFFS:
      return {
        ...state,
        staffs: null,
        error: null,
        currentStaff: null
      };
    case SET_CURRENT_STAFF:
      return {
        ...state,
        currentStaff: action.payload
      };
    case SET_CURRENT_SHIFT:
      return {
        ...state,
        currentShift: action.payload
      };
    case CLEAR_CURRENT_STAFF:
      return {
        ...state,
        currentStaff: null
      };
    case STAFF_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
