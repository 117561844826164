import React, { useContext } from 'react';
import CustomerContext from '../../context/customer/customerContext';

const AddCustomerBtn = () => {
  const customerContext = useContext(CustomerContext);

  const { clearCurrentCustomer } = customerContext;

  const clearAll = () => {
    clearCurrentCustomer();
  };

  return (
    <div className="fixed-action-btn">
      <a href="#add-customer-modal" className="btn-floating btn-large teal modal-trigger" onClick={clearAll}>
        <i className="large material-icons">add</i>
      </a>
    </div>
  )
}

export default AddCustomerBtn;