import React, { useContext } from 'react';
import AppointmentContext from '../../context/appointment/appointmentContext';

const AddAppointmentBtn = () => {
  const appointmentContext = useContext(AppointmentContext);

  const { clearCurrentAppointment } = appointmentContext;

  const clearAll = () => {
    clearCurrentAppointment();
  };

  return (
    <div className="fixed-action-btn">
      <a href="#add-appointment-modal" className="btn-floating btn-large teal modal-trigger" onClick={clearAll}>
        <i className="large material-icons">add</i>
      </a>
    </div>
  )
}

export default AddAppointmentBtn;