import React, { useReducer } from 'react';
import axios from 'axios';
import SettingContext from './settingContext';
import settingReducer from './settingReducer';
import {
  UPDATE_SETTING,
  UPDATE_BILL_SETTING,
  SETTING_ERROR,
  CLEAR_SETTING_ERRORS
} from '../types';

const SettingState = props => {
  const initialState = {
    setting: null,
    bill_setting: null,
    error: null
  };

  const [state, dispatch] = useReducer(settingReducer, initialState);

  // Update Setting
  const updateSetting = async setting => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.put(
        `/api/users/${setting._id}`,
        setting,
        config
      );

      dispatch({
        type: UPDATE_SETTING,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: SETTING_ERROR,
        payload: err.response.data
      });
    }
  };

  const updateBillSetting = async id => {
    try {
      const res = await axios.get(`/api/billings/${id}`);
      dispatch({
        type: UPDATE_BILL_SETTING,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: SETTING_ERROR,
        payload: err.response.data
      });
    }
  };

  // Clear Setting Errors
  const clearSettingErrors = () => dispatch({ type: CLEAR_SETTING_ERRORS });

  return (
    <SettingContext.Provider
      value={{
        setting: state.setting,
        bill_setting: state.bill_setting,
        error: state.error,
        updateSetting,
        updateBillSetting,
        clearSettingErrors
      }}
    >
      {props.children}
    </SettingContext.Provider>
  );
};

export default SettingState;
