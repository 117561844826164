import React, { useReducer } from 'react';
import axios from 'axios';
import CheckoutContext from './checkoutContext';
import checkoutReducer from './checkoutReducer';
import {
  GET_CHECKOUTS,
  CHECKOUT_ERROR,
  SET_CURRENT_CHECKOUT,
  CLEAR_CURRENT_CHECKOUT,
  UPDATE_CHECKOUT,
} from '../types';

const CheckoutState = props => {
  const initialState = {
    checkouts: null,
    currentCheckout: null,
    error: null
  };

  const [state, dispatch] = useReducer(checkoutReducer, initialState);

  // Get checkouts
  const getCheckouts = async () => {
    try {
      const res = await axios.get('/api/checkouts');

      dispatch({
        type: GET_CHECKOUTS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: CHECKOUT_ERROR,
        payload: err.response.data
      });
    }
  };

  // Update Checkout
  const updateCheckout = async checkout => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { _id, note, amount } = checkout
    try {
      const res = await axios.put(
        `/api/checkouts/${_id}`,
        { note, amount },
        config
      );

      dispatch({
        type: UPDATE_CHECKOUT,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: CHECKOUT_ERROR,
        payload: err.response.data
      });
    }
  };

  // Set Current Checkout
  const setCurrentCheckout = checkout => {
    dispatch({ type: SET_CURRENT_CHECKOUT, payload: checkout });
  };

  // Clear Current Checkout
  const clearCurrentCheckout = () => {
    dispatch({ type: CLEAR_CURRENT_CHECKOUT });
  };

  return (
    <CheckoutContext.Provider
      value={{
        checkouts: state.checkouts,
        currentCheckout: state.currentCheckout,
        error: state.error,
        getCheckouts,
        setCurrentCheckout,
        clearCurrentCheckout,
        updateCheckout,
      }}
    >
      {props.children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutState;
