import React, { useState, useContext, useEffect, Fragment } from 'react';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import PageContext from '../../context/page/pageContext';

const RegisterPage = props => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  const { setAlert } = alertContext;
  const { register, error, clearErrors, isAuthenticated } = authContext;

  useEffect(() => {
    pageContext.selectPage("Register")

    if (isAuthenticated) {
      props.history.push('/');
    }

    if (error === 'User already exists' || error === 'Invalid key') {
      setAlert(error, 'red');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    key: ''
  });

  const { name, email, password, password2, key } = user;

  const onChange = e => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (name === '' || email === '' || password === '' || key === '') {
      setAlert('Please enter all fields', 'red');
    } else if (password !== password2) {
      setAlert('Passwords do not match', 'red');
    } else {
      register({
        name,
        email,
        password,
        key
      });
    }
  };

  return (
    <Fragment>
    <div className="row">
      <div className="col l8 offset-l2 m10 offset-m1 s12">
        <div className="card card-login">
          <div className="card-login-splash">
            <div className="wrapper">
              <h4>Who is in/out?</h4>
            </div>
            <img src="/images/geometric.jpg" alt="" width="100%" height="100%"></img>
          </div>
          <div className="card-content">
            <span className="card-title">Register</span>
            <form onSubmit={onSubmit}>
              <div className='input-field'>
                <label htmlFor='name'>Salon Name</label>
                <input
                  type='text'
                  name='name'
                  placeholder=" "
                  value={name}
                  onChange={onChange}
                  required
                />
              </div>
              <div className='input-field'>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  name='email'
                  placeholder=" "
                  value={email}
                  onChange={onChange}
                  required
                />
              </div>
              <div className='input-field'>
                <label htmlFor='password'>Password</label>
                <input
                  type='password'
                  name='password'
                  placeholder=" "
                  value={password}
                  onChange={onChange}
                  required
                  minLength='6'
                />
              </div>
              <div className='input-field'>
                <label htmlFor='password2'>Confirm Password</label>
                <input
                  type='password'
                  name='password2'
                  placeholder=" "
                  value={password2}
                  onChange={onChange}
                  required
                  minLength='6'
                />
              </div>
              <div className='input-field'>
                <label htmlFor='key'>Register Key</label>
                <input
                  type='password'
                  name='key'
                  placeholder=" "
                  value={key}
                  onChange={onChange}
                  required
                />
              </div>
              <input
                type='submit'
                value='Register'
                className='btn btn-primary btn-block right'
              />
            </form>
          </div>
        </div>
      </div>    
    </div>
    <div className="row center">
      <div className="col l8 offset-l2 m10 offset-m1 s12">
        <div className="card">
          <div className="card-content">
            <p>"Our mission is to help businesses delight the customer by building 
            a long lasting relationship. Our digital platform allows the customer
            to review, connect, and have a present voice in their community's 
            favorite local business."</p>
        </div>
      </div>
    </div>
    </div>
    </Fragment>
  );
};

export default RegisterPage;
