import {
  GET_PROMOTIONS,
  ADD_PROMOTION,
  DELETE_PROMOTION,
  SET_CURRENT_PROMOTION,
  CLEAR_CURRENT_PROMOTION,
  UPDATE_PROMOTION,
  PROMOTION_ERROR,
  CLEAR_PROMOTIONS
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_PROMOTIONS:
      return {
        ...state,
        promotions: action.payload,
        loading: false
      };
    case ADD_PROMOTION:
      return {
        ...state,
        promotions: [action.payload, ...state.promotions],
        loading: false
      };
    case UPDATE_PROMOTION:
      return {
        ...state,
        promotions: state.promotions.map(promotion =>
          promotion._id === action.payload._id ? action.payload : promotion
        ),
        loading: false
      };
    case DELETE_PROMOTION:
      return {
        ...state,
        promotions: state.promotions.filter(
          promotion => promotion._id !== action.payload
        ),
        loading: false
      };
    case CLEAR_PROMOTIONS:
      return {
        ...state,
        promotions: null,
        error: null,
        currentPromotion: null
      };
    case SET_CURRENT_PROMOTION:
      return {
        ...state,
        currentPromotion: action.payload
      };
    case CLEAR_CURRENT_PROMOTION:
      return {
        ...state,
        currentPromotion: null
      };
    case PROMOTION_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
