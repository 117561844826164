import React, { useState, useContext, useEffect } from 'react';
import StaffContext from '../../context/staff/staffContext';

const StaffForm = () => {
  const staffContext = useContext(StaffContext);

  const { addStaff, currentStaff, clearCurrentStaff } = staffContext;

  useEffect(() => {
    if (currentStaff !== null) {
      setStaff(currentStaff);
    } else {
      setStaff({
        name: '',
        phone: '',
        shiftStart: ["","","","","","",""],
        shiftEnd: ["","","","","","",""]
      });
    }
  }, [currentStaff]);

  const [staff, setStaff] = useState({
    name: '',
    phone: '',
    shiftStart: ["","","","","","",""],
    shiftEnd: ["","","","","","",""]
  });

  const { name, phone } = staff;

  const onChange = e =>
    setStaff({ ...staff, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (currentStaff === null) {
      addStaff(staff);
    }
    clearAll();
  };

  const clearAll = () => {
    clearCurrentStaff();
  };

  // phone input format
  let filter = [];

  const keypadZero = 48;
  const numpadZero = 96;

  for(var i = 0; i <= 9; i++){
    filter.push(i + keypadZero);
    filter.push(i + numpadZero);  
  }

  filter.push(8);//backspace
  filter.push(9);//tab
  filter.push(46);//delete
  filter.push(37);//left arrow
  filter.push(39);//right arrow

  const replaceAll = (src,search,replace) => {
    return src.split(search).join(replace);
  }

  const formatPhoneText = s => {
    let value = replaceAll(s.trim(),"-","");
    
    if(value.length > 3 && value.length <= 6) 
      value = value.slice(0,3) + "-" + value.slice(3);
    else if(value.length > 6) 
      value = value.slice(0,3) + "-" + value.slice(3,6) + "-" + value.slice(6);
    
    return value;
  }

  const validatePhone = p => {
    var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/;
    var digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
  }

  const onKeyDown = e => {  
    if(filter.indexOf(e.keyCode) < 0){
      e.preventDefault();
      return false;
    }  
  }

  const onKeyUp = e => {
    var input = e.target;
    var formatted = formatPhoneText(input.value);
    var isError = (validatePhone(formatted) || formatted.length === 0);
    var color =  (isError) ? "gray" : "red";
    var borderWidth =  (isError)? "1px" : "3px";
    input.style.borderColor = color;
    input.style.borderWidth = borderWidth;
    input.value = formatted;
  }

  return (
    <div id="staff-modal" className="modal">
      <form onSubmit={onSubmit}>
      <div className="modal-content">
        <h6 className="center-align">
          { currentStaff ? 'Staff Information' : 'Add Staff' }
        </h6>
        <div className="input-field">
          <label htmlFor="name">Name</label>
          { currentStaff ?  
          (<input
            type="text"
            placeholder="Name"
            name="name"
            value={name}
            readOnly
          />) :
          (
            <input
            type="text"
            placeholder=""
            name="name"
            value={name}
            onChange={onChange}
          />
          )}
        </div>
        <div className="input-field">
          <label htmlFor="phone">Phone</label>
          { currentStaff ?  
          (<input
            type="tel"
            placeholder="Phone"
            name="phone"
            value={phone}
            readOnly
          />) :
          (
            <input
            type="tel"
            placeholder=""
            name="phone"
            value={phone}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />
          )}
        </div>
      </div>
      { !currentStaff &&
      <div className="modal-footer">
        <button
          type="submit"
          className="modal-close waves-effect waves-green btn-flat"><i className="material-icons right">add</i>Add Staff
        </button>
      </div>
      }
      </form>
    </div>
  );
};

export default StaffForm;
