import React, { useReducer } from 'react';
import axios from 'axios';
import PromotionContext from './promotionContext';
import promotionReducer from './promotionReducer';
import {
  GET_PROMOTIONS,
  ADD_PROMOTION,
  DELETE_PROMOTION,
  SET_CURRENT_PROMOTION,
  CLEAR_CURRENT_PROMOTION,
  UPDATE_PROMOTION,
  CLEAR_PROMOTIONS,
  PROMOTION_ERROR
} from '../types';

const PromotionState = props => {
  const initialState = {
    promotions: null,
    currentPromotion: null,
    error: null
  };

  const [state, dispatch] = useReducer(promotionReducer, initialState);

  // Get Promotions
  const getPromotions = async () => {
    try {
      const res = await axios.get('/api/promotions');

      dispatch({
        type: GET_PROMOTIONS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: PROMOTION_ERROR,
        payload: err.response.data
      });
    }
  };

  // Add Promotion
  const addPromotion = async promotion => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.post('/api/promotions', promotion, config);

      dispatch({
        type: ADD_PROMOTION,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: PROMOTION_ERROR,
        payload: err.response.data
      });
    }
  };

  // Delete Promotion
  const deletePromotion = async id => {
    try {
      await axios.delete(`/api/promotions/${id}`);

      dispatch({
        type: DELETE_PROMOTION,
        payload: id
      });
    } catch (err) {
      dispatch({
        type: PROMOTION_ERROR,
        payload: err.response.data
      });
    }
  };

  // Update Promotion
  const updatePromotion = async promotion => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.put(
        `/api/promotions/${promotion._id}`,
        promotion,
        config
      );

      dispatch({
        type: UPDATE_PROMOTION,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: PROMOTION_ERROR,
        payload: err.response.data
      });
    }
  };

  // Clear Promotions
  const clearPromotions = () => {
    dispatch({ type: CLEAR_PROMOTIONS });
  };

  // Set Current Promotion
  const setCurrentPromotion = promotion => {
    dispatch({ type: SET_CURRENT_PROMOTION, payload: promotion });
  };

  // Clear Current Promotion
  const clearCurrentPromotion = () => {
    dispatch({ type: CLEAR_CURRENT_PROMOTION });
  };

  return (
    <PromotionContext.Provider
      value={{
        promotions: state.promotions,
        currentPromotion: state.currentPromotion,
        error: state.error,
        addPromotion,
        deletePromotion,
        setCurrentPromotion,
        clearCurrentPromotion,
        updatePromotion,
        getPromotions,
        clearPromotions
      }}
    >
      {props.children}
    </PromotionContext.Provider>
  );
};

export default PromotionState;
