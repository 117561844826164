import React from 'react';

const RefreshCheckoutBtn = () => {
  return (
    <div className="fixed-action-btn">
      <a href="/checkouts" className="btn-floating btn-large teal modal-trigger">
        <i className="large material-icons">refresh</i>
      </a>
    </div>
  )
}

export default RefreshCheckoutBtn;