import {
  GET_REVIEWS,
  UPDATE_REVIEW,
  GET_CURRENT_REVIEW,
  SET_CURRENT_REVIEW,
  CLEAR_CURRENT_REVIEW,
  REVIEW_ERROR
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
        loading: false
      };
    case UPDATE_REVIEW:
      return {
        ...state,
        currentReview: action.payload
      };
    case GET_CURRENT_REVIEW:
      return {
        ...state,
        currentReview: action.payload,
        loading: false
      };
    case SET_CURRENT_REVIEW:
      return {
        ...state,
        currentReview: action.payload
      };
    case CLEAR_CURRENT_REVIEW:
      return {
        ...state,
        currentReview: null
      };
    case REVIEW_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
