import React, { useReducer } from 'react';
import axios from 'axios';
import AppointmentContext from './appointmentContext';
import appointmentReducer from './appointmentReducer';
import {
  APPOINTMENT_ERROR,
  GET_APPOINTMENTS,
  GET_CURRENT_APPOINTMENT,
  SET_CURRENT_APPOINTMENT,
  CLEAR_CURRENT_APPOINTMENT,
  ADD_APPOINTMENT,
  UPDATE_APPOINTMENT,
} from '../types';

const AppointmentState = props => {
  const initialState = {
    appointments: null,
    currentAppointment: null,
    error: null
  };

  const [state, dispatch] = useReducer(appointmentReducer, initialState);

  // Get appointments
  const getAppointments = async () => {
    try {
      const res = await axios.get('/api/appointments');
      
      dispatch({
        type: GET_APPOINTMENTS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: APPOINTMENT_ERROR,
        payload: err.response.data
      });
    }
  };

  // Get current appointment
  const getCurrentAppointment = async id => {
    try {
      const appointment = await axios.get(`/api/appointments/${id}`);

      const user = await axios.get(`/api/users/${appointment.data.user}`);

      dispatch({
        type: GET_CURRENT_APPOINTMENT,
        payload: Object.assign(appointment.data, user.data)
      });
    } catch (err) {
      dispatch({
        type: APPOINTMENT_ERROR,
        payload: err.response.data
      });
    }
  };

  // Add Appointment
  const addAppointment = async appointment => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    try {
      const res = await axios.post('/api/appointments', appointment, config);

      dispatch({
        type: ADD_APPOINTMENT,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: APPOINTMENT_ERROR,
        payload: err.response.data
      });
    }
  };

  // Update appointment
  const updateAppointment = async appointment => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { _id, name, phone, note, start, end } = appointment
    try {
      const res = await axios.put(
        `/api/appointments/${_id}`,
        { name, phone, note, start, end },
        config
      );

      dispatch({
        type: UPDATE_APPOINTMENT,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: APPOINTMENT_ERROR,
        payload: err.response.data
      });
    }
  };

  // Set current appointment
  const setCurrentAppointment = async appointment => {
    try {
      const user = await axios.get(`/api/users/${appointment.user}`);

      dispatch({ 
        type: SET_CURRENT_APPOINTMENT,
        payload: Object.assign(appointment, user.data)
      });
    } catch (err) {
      dispatch({
        type: APPOINTMENT_ERROR,
        payload: err.response.data
      });
    }
  };

  // Clear current appointment
  const clearCurrentAppointment = () => {
    dispatch({ type: CLEAR_CURRENT_APPOINTMENT });
  };

  return (
    <AppointmentContext.Provider
      value={{
        appointments: state.appointments,
        currentAppointment: state.currentAppointment,
        error: state.error,
        getAppointments,
        getCurrentAppointment,
        setCurrentAppointment,
        clearCurrentAppointment,
        addAppointment,
        updateAppointment,
      }}
    >
      {props.children}
    </AppointmentContext.Provider>
  );
};

export default AppointmentState;
