import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import Services from '../services/Services';
import AddServiceBtn from '../services/AddServiceBtn';
import ServiceForm from '../services/ServiceForm';
import PageContext from '../../context/page/pageContext';

const ServicesPage = () => {
  const authContext = useContext(AuthContext);
  const pageContext = useContext(PageContext);

  useEffect(() => {
    authContext.loadUser();
    pageContext.selectPage("Services")
    // eslint-disable-next-line
  }, []);

  
  return (
    <div className="row">
      <AddServiceBtn />
      <ServiceForm />
      <div className="card">
        <div className="row">
          <Services />
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
