import {
  GET_CHECKOUTS,
  UPDATE_CHECKOUT,
  SET_CURRENT_CHECKOUT,
  CLEAR_CURRENT_CHECKOUT,
  CHECKOUT_ERROR
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_CHECKOUTS:
      return {
        ...state,
        checkouts: action.payload,
        loading: false
      };
    case UPDATE_CHECKOUT:
      return {
        ...state,
        checkouts: state.checkouts.filter(checkout =>
          checkout._id !== action.payload._id
        ),
        loading: false
      };
    case SET_CURRENT_CHECKOUT:
      return {
        ...state,
        currentCheckout: action.payload
      };
    case CLEAR_CURRENT_CHECKOUT:
      return {
        ...state,
        currentCheckout: null
      };
    case CHECKOUT_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};
