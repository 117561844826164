import React, { useState, useContext, useEffect } from 'react';
import ReminderContext from '../../context/reminder/reminderContext';

const ReminderForm = () => {
  const reminderContext = useContext(ReminderContext);
  const { addReminder, updateReminder, clearCurrentReminder, currentReminder } = reminderContext;

  useEffect(() => {
    if (currentReminder !== null) {
      setReminder(currentReminder);
    } else {
      setReminder({
        days: 0,
        period: 0,
        discount: 0,
        type: "$",
        status: "on"
      });
    }
  }, [reminderContext, currentReminder]);

  const [reminder, setReminder] = useState({
    days: 0,
    period: 0,
    discount: 0,
    type: "$",
    status: "on"
  });

  const { days, period, discount, type, status } = reminder;

  const onChange = e =>
    setReminder({ ...reminder, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    if (currentReminder === null) {
      addReminder(reminder);
    } else {
      updateReminder(reminder);
    }
    clearAll();
  };

  const clearAll = () => {
    clearCurrentReminder();
  };

  return (
    <div id="add-reminder-modal" className="modal">
      <form onSubmit={onSubmit}>
      <div className="modal-content">
        <h6 className="center-align">
          {currentReminder ? 'Edit Reminder' : 'Add Reminder'}
        </h6>
        <div class="row">
          <div className="input-field col s12">
            <label htmlFor="days">Remind After # Days</label>
            <input
              type="number"
              placeholder=""
              name="days"
              value={days}
              onChange={onChange}
            />
          </div>
        </div>
        <div class="row">
          <div className="input-field col s12">
            <label htmlFor="period">Valid In # Days</label>
            <input
              type="number"
              placeholder=""
              name="period"
              value={period}
              onChange={onChange}
            />
          </div>
        </div>
        <div class="row">
          <div className="input-field col s4">
            <label htmlFor="discount">Discount</label>
            <input
              type="number"
              placeholder=""
              name="discount"
              value={discount}
              onChange={onChange}
            />
          </div>
          <div className="input-field col s8">
            <p className="col s4">
              <label>
                <input name="type" type="radio" value="$" checked={type==="$"?true:false} onChange={onChange}/>
                <span>$</span>
              </label>
            </p>
            <p className="col s4">
              <label>
                <input name="type" type="radio" value="%" checked={type==="%"?true:false} onChange={onChange}/>
                <span>%</span>
              </label>
            </p>    
          </div>
        </div>
        <div class="row">
          <div className="input-field col s4">
            <label htmlFor="status">Status</label>
            <input
              type="text"
              readOnly
              placeholder=""
              value={status==="on"?"Running":"Not running"}
            />
          </div>
          <div className="input-field col s8">
            <p className="col s4">
              <label>
                <input name="status" type="radio" value="on" checked={status==="on"?true:false} onChange={onChange}/>
                <span>ON</span>
              </label>
            </p>
            <p className="col s4">
              <label>
                <input name="status" type="radio" value="off" checked={status==="off"?true:false} onChange={onChange}/>
                <span>OFF</span>
              </label>
            </p>    
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="modal-close btn-large btn-flat"><i className="material-icons right">{currentReminder ? "edit" : "add"}</i>{currentReminder ? "Edit Reminder" : "Add Reminder"}
        </button>
      </div>
      </form>
    </div>
  );
};

export default ReminderForm;
