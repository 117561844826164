import React, { useReducer } from 'react';
import PageContext from './pageContext';
import pageReducer from './pageReducer';

import {
  SELECT_PAGE
} from '../types';

const PageState = props => {
  const initialState = {
    page: ""
  };

  const [state, dispatch] = useReducer(pageReducer, initialState);

  // select page
  const selectPage = (name) => {
    dispatch({
      type: SELECT_PAGE,
      payload: name
    });
  };

  return (
    <PageContext.Provider
      value={{
        page: state.page,
        selectPage
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
};

export default PageState;
