import React, { useState, useContext, useEffect } from 'react';
import RewardContext from '../../context/reward/rewardContext';
import AlertContext from '../../context/alert/alertContext';

const RewardForm = () => {
  const rewardContext = useContext(RewardContext);
  const alertContext = useContext(AlertContext);

  const { addReward, updateReward, clearCurrentReward, currentReward } = rewardContext;
  const { setAlert } = alertContext;

  useEffect(() => {
    if (currentReward !== null) {
      setReward(currentReward);
    } else {
      setReward({
        visits: 0,
        points: 0,
        discount: 0,
        type: "$"
      });
    }
  }, [rewardContext, currentReward]);

  const [reward, setReward] = useState({
    visits: 0,
    points: 0,
    discount: 0,
    type: "$"
  });

  const { visits, points, discount, type } = reward;

  const onChange = e =>
    setReward({ ...reward, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();

    if ((reward.visits === 0) && (reward.points === 0))
      setAlert('Invalid visits and points!', 'red', 3000);

    if (reward.discount === 0)
      setAlert('Invalid discount!', 'red', 3000);

    if (currentReward === null) {
      addReward(reward);
    } else {
      updateReward(reward);
    }
    clearAll();
  };

  const clearAll = () => {
    clearCurrentReward();
  };

  return (
    <div id="add-reward-modal" className="modal">
      <form onSubmit={onSubmit}>
      <div className="modal-content">
        <h6 className="center-align">
          {currentReward ? 'Edit Reward' : 'Add Reward'}
        </h6>
        <div class="row">
          <div className="input-field col s12">
            <label htmlFor="visits">Visits</label>
            <input
              type="number"
              placeholder=""
              name="visits"
              value={visits}
              onChange={onChange}
            />
          </div>
        </div>
        <div class="row">
          <div className="input-field col s12">
            <label htmlFor="points">Points</label>
            <input
              type="number"
              placeholder=""
              name="points"
              value={points}
              onChange={onChange}
            />
          </div>
        </div>
        <div class="row">
          <div className="input-field col s4">
            <label htmlFor="discount">Discount</label>
            <input
              type="number"
              placeholder=""
              name="discount"
              value={discount}
              onChange={onChange}
            />
          </div>
          <div className="input-field col s8">
            <p className="col s4">
              <label>
                <input name="type" type="radio" value="$" checked={type==="$"?true:false} onChange={onChange}/>
                <span>$</span>
              </label>
            </p>
            <p className="col s4">
              <label>
                <input name="type" type="radio" value="%" checked={type==="%"?true:false} onChange={onChange}/>
                <span>%</span>
              </label>
            </p>    
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="modal-close btn-large btn-flat"><i className="material-icons right">{currentReward ? "edit" : "add"}</i>{currentReward ? "Edit Reward" : "Add Reward"}
        </button>
      </div>
      </form>
    </div>
  );
};

export default RewardForm;
