import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReminderContext from '../../context/reminder/reminderContext';

const ReminderItem = ({ reminder }) => {
  const reminderContext = useContext(ReminderContext);
  const { deleteReminder, setCurrentReminder, clearCurrentReminder } = reminderContext;

  const { _id, period, days, discount, type, status } = reminder;

  const onDelete = () => {
    deleteReminder(_id);
    clearCurrentReminder();
  };

  return (
    <tr role="row">
      <td>{days} days</td>
      <td>{period} days</td>
      <td>
        {type === "$" ? "$"+ (discount) : (discount) + "%"}
      </td>
      <td>{status === "on" ? "Running" : "Not running"}</td>
      <td>
        <a href="#add-reminder-modal" className="waves-effect waves-light modal-trigger" onClick={() => setCurrentReminder(reminder)}>
          <i className="material-icons teal-text">edit</i>
        </a>
      </td>
      <td>
        <a href="#" className="waves-effect waves-light modal-trigger" onClick={onDelete}>
          <i className="material-icons red-text">delete</i>
        </a>
      </td>
    </tr>
  );
};

ReminderItem.propTypes = {
  reminder: PropTypes.object.isRequired
};

export default ReminderItem;
