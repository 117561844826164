import React, { useState, useContext, useEffect, Fragment } from 'react';
import PageContext from '../../context/page/pageContext';
import AlertContext from '../../context/alert/alertContext';
import AppointmentContext from '../../context/appointment/appointmentContext';
import M from 'materialize-css/dist/js/materialize.min.js';

const AppointmentsPage = () => {
  const alertContext = useContext(AlertContext);
  const pageContext = useContext(PageContext);

  const appointmentContext = useContext(AppointmentContext);

  const { setAlert } = alertContext;
  const { currentAppointment, getCurrentAppointment, updateAppointment, setCurrentAppointment } = appointmentContext;

  useEffect(() => {
    pageContext.selectPage("Appointments");
    let id = window.location.pathname.replace("/appointments/", "");
    getCurrentAppointment(id);
    if (currentAppointment !== null) {
      setCurrentAppointment(currentAppointment);
    } else {
      setName("");
      setPhone("");
      setDate(null);
      setTime("");
    }
    // eslint-disable-next-line
  }, [currentAppointment]);

  const [name, setName] = useState("");
  const onChangeName = e => setName(e.target.value);

  const [phone, setPhone] = useState("");
  const onChangePhone = e => setPhone(e.target.value);

  const [note, setNote] = useState("");
  const onChangeNote = e => setNote(e.target.value);

  const [date, setDate] = useState(null);
  const onClickDate = e => setDate(M.Datepicker.getInstance(e.target));

  const [time, setTime] = useState("");
  const onChangeTime = e => setTime(e.target.value);

  // phone input format
  let filter = [];

  const keypadZero = 48;
  const numpadZero = 96;

  for(var i = 0; i <= 9; i++){
    filter.push(i + keypadZero);
    filter.push(i + numpadZero);  
  }

  filter.push(8);//backspace
  filter.push(9);//tab
  filter.push(46);//delete
  filter.push(37);//left arrow
  filter.push(39);//right arrow

  const replaceAll = (src,search,replace) => {
    return src.split(search).join(replace);
  }

  const formatPhoneText = s => {
    let value = replaceAll(s.trim(),"-","");
    
    if(value.length > 3 && value.length <= 6) 
      value = value.slice(0,3) + "-" + value.slice(3);
    else if(value.length > 6) 
      value = value.slice(0,3) + "-" + value.slice(3,6) + "-" + value.slice(6);
    
    return value;
  }

  const validatePhone = p => {
    var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/;
    var digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
  }

  const onKeyDown = e => {  
    if(filter.indexOf(e.keyCode) < 0){
      e.preventDefault();
      return false;
    }  
  }

  const onKeyUp = e => {
    var input = e.target;
    var formatted = formatPhoneText(input.value);
    var isError = (validatePhone(formatted) || formatted.length === 0);
    var color =  (isError) ? "gray" : "red";
    var borderWidth =  (isError)? "1px" : "3px";
    input.style.borderColor = color;
    input.style.borderWidth = borderWidth;
    input.value = formatted;
  }

  const onSubmitAppointment = e => {
    //e.preventDefault();
    try {
      if (name === "" || phone ==="") {
        setAlert('Please provide name and phone for your appointment!', 'orange', 10000);
        return;
      }
      if (date === null || time ==="") {
        setAlert('Please select a date and time for your appointment!', 'orange', 10000);
        return;
      }
      const start = new Date(date.toString() + " " + time);
      if (start < Date.now()) {
        setAlert('Please select an valid time for your appointment!', 'orange', 10000);
        return;
      }
      const end = new Date(start.getTime() + 60*60000);
      const updated = currentAppointment.updated;
      updateAppointment({_id: currentAppointment._id, name, phone, note, start, end});
      if (updated) {
        setAlert('Your appointment is updated, thank you!', 'green', 10000);
      } else {
        setAlert('Your appointment is booked, thank you!', 'green', 10000);
      }
    } catch (err) {
      setAlert('Sorry, we cannot schedule your appointment! The appointment link is expired', 'orange');
    }
  };

  return (
    <div className="row">
    <div className="col s12">
      <div className="card">
        <div className="card-content">
          <div className="row center">
            <span className="card-title center teal-text text-darken-2">{currentAppointment && currentAppointment.salonName}</span>
            <span>
              {currentAppointment && currentAppointment.salonAddress && currentAppointment.salonAddress.street}, {currentAppointment && currentAppointment.salonAddress && currentAppointment.salonAddress.city}, {currentAppointment && currentAppointment.salonAddress && currentAppointment.salonAddress.state}, {currentAppointment && currentAppointment.salonAddress && currentAppointment.salonAddress.zip}
            </span>
          </div>
          <div className="row center">
            <p>We always try to make our customers happy, please book your appointment online or 
              by phone at <a href={currentAppointment && ("tel:" + currentAppointment.salonPhone)}>{currentAppointment && currentAppointment.salonPhone}</a>
            </p>
          </div>
          <div className="row">
          <div className="input-field col s12">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            placeholder=""
            name="name"
            onChange={onChangeName}
          />
          </div>
          <div className="input-field col s12">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              placeholder=""
              name="phone"
              onChange={onChangePhone}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
            />
          </div>
          <div className="input-field col s12">
          <label htmlFor="note">What can we do to serve your needs?</label>
          <input
            type="text"
            placeholder=""
            name="note"
            onChange={onChangeNote}
          />
          </div>
          </div>
          <div className="row">
          <div className="input-field col s12">
            <input name="date" 
              type="text" 
              placeholder=""
              className="datepicker"
              onClick={onClickDate}
            />
            <label htmlFor="date">Please select a date</label>
          </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <label>MORNING</label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="10:00" onClick={onChangeTime}/>
                <span>10:00 AM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="10:30" onClick={onChangeTime}/>
                <span>10:30 AM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="11:00" onClick={onChangeTime}/>
                <span>11:00 AM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="11:30" onClick={onChangeTime}/>
                <span>11:30 AM</span>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <label>AFTERNOON</label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="12:00" onClick={onChangeTime}/>
                <span>12:00 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="12:30" onClick={onChangeTime}/>
                <span>12:30 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="13:00" onClick={onChangeTime}/>
                <span>1:00 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="13:30" onClick={onChangeTime}/>
                <span>1:30 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="14:00" onClick={onChangeTime}/>
                <span>2:00 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="14:30" onClick={onChangeTime}/>
                <span>2:30 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="15:00" onClick={onChangeTime}/>
                <span>3:00 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="15:30" onClick={onChangeTime}/>
                <span>3:30 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="16:00" onClick={onChangeTime}/>
                <span>4:00 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="16:30" onClick={onChangeTime}/>
                <span>4:30 PM</span>
              </label>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s12">
              <label>EVENING</label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="17:00" onClick={onChangeTime}/>
                <span>5:00 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="17:30" onClick={onChangeTime}/>
                <span>5:30 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="18:00" onClick={onChangeTime}/>
                <span>6:00 PM</span>
              </label>
            </div>
            <div className="input-field col s6">
              <label>
                <input name="time" type="radio" value="18:30" onClick={onChangeTime}/>
                <span>6:30 PM</span>
              </label>
            </div>
          </div>
        </div>
        <div className="card-action">
          <div className="row">
            <a href="#" className="right teal-text text-darken-2" onClick={onSubmitAppointment}>
              <i className="waves-effect waves-light right material-icons">send</i> <b>Book</b>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default AppointmentsPage;
