import React, { useState, useContext, useEffect } from 'react';
import AppointmentContext from '../../context/appointment/appointmentContext';
import M from 'materialize-css/dist/js/materialize.min.js';

const AppointmentForm = () => {
  const appointmentContext = useContext(AppointmentContext);

  const { addAppointment, updateAppointment, clearCurrentAppointment, currentAppointment } = appointmentContext;

  useEffect(() => {
    if (currentAppointment !== null) {
      setAppointment(currentAppointment);
    } else {
      setAppointment({
        name: '',
        phone: '',
        note: '',
        start: null,
        end: null
      });
    }
  }, [appointmentContext, currentAppointment]);

  const [appointment, setAppointment] = useState({
    name: '',
    phone: '',
    note: '',
    start: null,
    end: null
  });

  const [name, setName] = useState("");
  const onChangeName = e => setName(e.target.value);

  const [phone, setPhone] = useState("");
  const onChangePhone = e => setPhone(e.target.value);

  const [note, setNote] = useState("");
  const onChangeNote = e => setNote(e.target.value);

  const [date, setDate] = useState(null);
  const onClickDate = e => setDate(M.Datepicker.getInstance(e.target));

  const [time, setTime] = useState(null);
  const onChangeTime = e => setTime(e.target.value);

  const mmddyyyy = e => {
    var mm = e.getUTCMonth();
    var dd = e.getUTCDate();

    return [
      (mm>9 ? '' : '0') + mm,
      (dd>9 ? '' : '0') + dd,
      e.getUTCFullYear(),
     ].join('/');
  }

  const onSubmit = e => {
    e.preventDefault();
    
    const start = new Date(date.toString() + " " + time.toString());
    if (start < Date.now()) {
      return;
    }
    const end = new Date(start.getTime() + 3600000);
    if (currentAppointment === null) {
      addAppointment({name, phone, note, start, end, updated: true});
    } else {
      updateAppointment({name, phone, note, start, end});
    }
    clearAll();
  };

  const clearAll = () => {
    clearCurrentAppointment();
  };

  // phone input format
  let filter = [];

  const keypadZero = 48;
  const numpadZero = 96;
  
  for(var i = 0; i <= 9; i++){
    filter.push(i + keypadZero);
    filter.push(i + numpadZero);  
  }
  
  filter.push(8);//backspace
  filter.push(9);//tab
  filter.push(46);//delete
  filter.push(37);//left arrow
  filter.push(39);//right arrow
  
  const replaceAll = (src,search,replace) => {
    return src.split(search).join(replace);
  }
  
  const formatPhoneText = s => {
    let value = replaceAll(s.trim(),"-","");
    
    if(value.length > 3 && value.length <= 6) 
      value = value.slice(0,3) + "-" + value.slice(3);
    else if(value.length > 6) 
      value = value.slice(0,3) + "-" + value.slice(3,6) + "-" + value.slice(6);
    
    return value;
  }
  
  const validatePhone = p => {
    var phoneRe = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/;
    var digits = p.replace(/\D/g, "");
    return phoneRe.test(digits);
  }
  
  const onKeyDown = e => {  
    if(filter.indexOf(e.keyCode) < 0){
      e.preventDefault();
      return false;
    }  
  }
  
  const onKeyUp = e => {
    var input = e.target;
    var formatted = formatPhoneText(input.value);
    var isError = (validatePhone(formatted) || formatted.length === 0);
    var color =  (isError) ? "gray" : "red";
    var borderWidth =  (isError)? "1px" : "3px";
    input.style.borderColor = color;
    input.style.borderWidth = borderWidth;
    input.value = formatted;
  }

  return (
    <div id="add-appointment-modal" className="modal">
      <form onSubmit={onSubmit}>
      <div className="modal-content">
        <h6 className="center-align">
          {currentAppointment ? 'Edit Appointment' : 'Add Appointment'}
        </h6>
        <div className="input-field">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            placeholder=""
            name="name"
            value={name}
            onChange={onChangeName}
          />
        </div>
        <div className="input-field">
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            placeholder=""
            name="phone"
            value={phone}
            onChange={onChangePhone}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />
        </div>
        <div className="input-field">
          <label htmlFor="note">Note</label>
          <input
            type="text"
            placeholder=""
            name="note"
            value={note}
            onChange={onChangeNote}
          />
        </div>
        <div className="input-field">
          <label htmlFor="date">Date</label>
          <input name="date" 
            type="text" 
            placeholder=""
            className="datepicker"
            onClick={onClickDate}
          />
        </div>
        <div className="input-field">
          <label htmlFor="time">Time</label>
          <input
            type="time"
            placeholder=""
            name="time"
            value={time}
            onChange={onChangeTime}
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="modal-close waves-effect waves-green btn-flat"><i className="material-icons right">{currentAppointment ? "edit" : "add"}</i>{currentAppointment ? "Edit Appointment" : "Add Appointment"}
        </button>
      </div>
      </form>
    </div>
  );
};

export default AppointmentForm;
