import React, { Fragment, useContext} from 'react';
import PageContext from '../../context/page/pageContext';

const Footer = () => {
  const pageContext = useContext(PageContext);
  const { page } = pageContext;

  // // if (page === "Log In") {
  // //   return (
  // //     <footer className="page-footer" style={{position:'fixed',bottom:0,left:0,width:'100%'}}>
  // //       <div className="container">
  // //         <div className="row">
  // //           <div className="col s6 m3 center">
  // //             <img className="materialize-logo" src="images/logo.png" alt="Materialize" width="96" height="64"></img>
  // //             <p>@2019 whoisinout.com</p>
  // //           </div>
  // //           <div className="col s6 m3 center">
  // //             <h5>About</h5>
  // //             <ul>
  // //               <li><a href="#!">Pricing</a></li>
  // //               <li><a href="#!">Docs</a></li>
  // //             </ul>
  // //           </div>
  // //           <div className="col s6 m3 center">
  // //             <h5>Connect</h5>
  // //             <ul>
  // //               <li><a href="#!">Subscribe</a></li>
  // //               <li><a href="#!">Email</a></li>
  // //             </ul>
  // //           </div>
  // //           <div className="col s6 m3 center">
  // //             <h5>Contact</h5>
  // //             <ul>
  // //               <li><a href="#!">Facebook</a></li>
  // //               <li><a href="#!">Github</a></li>
  // //             </ul>
  // //           </div>
  // //         </div>
  // //       </div>
  // //     </footer>
  // //   );
  // // }

  if (page === "Log In" || page === "Register") {
    return (
      <footer className="page-footer" style={{position:'fixed',bottom:0,left:0,width:'100%'}}>
        <p className="center">@ {new Date().getFullYear()} whoinout.com. All rights reserved.</p>
      </footer>
    )
  }

  return (
    <Fragment>
    </Fragment>
  )
};

export default Footer;
